export const LOGOUT = 'LOGOUT';

export const SET_LANGUAGE_START = 'SET_LANGUAGE_START';
export const SET_LANGUAGE_SUCCESS = 'SET_LANGUAGE_SUCCESS';
export const SET_LANGUAGE_ERROR = 'SET_LANGUAGE_ERROR';

export const POST_LOGIN_START = 'POST_LOGIN_START';
export const POST_LOGIN_SUCCESS = 'POST_LOGIN_SUCCESS';
export const POST_LOGIN_ERROR = 'POST_LOGIN_ERROR';

//

export const GET_ABOUT_US_START = 'GET_ABOUT_US_START';
export const GET_ABOUT_US_SUCCESS = 'GET_ABOUT_US_SUCCESS';
export const GET_ABOUT_US_ERROR = 'GET_ABOUT_US_ERROR';

export const GET_BANNERS_START = 'GET_BANNERS_START';
export const GET_BANNERS_SUCCESS = 'GET_BANNERS_SUCCESS';
export const GET_BANNERS_ERROR = 'GET_BANNERS_ERROR';

export const GET_FAQ_START = 'GET_FAQ_START';
export const GET_FAQ_SUCCESS = 'GET_FAQ_SUCCESS';
export const GET_FAQ_ERROR = 'GET_FAQ_ERROR';

export const GET_INFO_CARDS_START = 'GET_INFO_CARDS_START';
export const GET_INFO_CARDS_SUCCESS = 'GET_INFO_CARDS_SUCCESS';
export const GET_INFO_CARDS_ERROR = 'GET_INFO_CARDS_ERROR';

export const GET_LIST_ITEMS_START = 'GET_LIST_ITEMS_START';
export const GET_LIST_ITEMS_SUCCESS = 'GET_LIST_ITEMS_SUCCESS';
export const GET_LIST_ITEMS_ERROR = 'GET_LIST_ITEMS_ERROR';

export const GET_OUR_WORK_START = 'GET_OUR_WORK_START';
export const GET_OUR_WORK_SUCCESS = 'GET_OUR_WORK_SUCCESS';
export const GET_OUR_WORK_ERROR = 'GET_OUR_WORK_ERROR';

export const GET_ENTERPRISES_START = 'GET_ENTERPRISES_START';
export const GET_ENTERPRISES_SUCCESS = 'GET_ENTERPRISES_SUCCESS';
export const GET_ENTERPRISES_ERROR = 'GET_ENTERPRISES_ERROR';

export const GET_FOUNDERS_START = 'GET_FOUNDERS_START';
export const GET_FOUNDERS_SUCCESS = 'GET_FOUNDERS_SUCCESS';
export const GET_FOUNDERS_ERROR = 'GET_FOUNDERS_ERROR';

export const GET_PORTOFOLIO_START = 'GET_PORTOFOLIO_START';
export const GET_PORTOFOLIO_SUCCESS = 'GET_PORTOFOLIO_SUCCESS';
export const GET_PORTOFOLIO_ERROR = 'GET_PORTOFOLIO_ERROR';

export const GET_STAFF_CARDS_START = 'GET_STAFF_CARDS_START';
export const GET_STAFF_CARDS_SUCCESS = 'GET_STAFF_CARDS_SUCCESS';
export const GET_STAFF_CARDS_ERROR = 'GET_STAFF_CARDS_ERROR';

export const GET_STAFF_START = 'GET_STAFF_START';
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';
export const GET_STAFF_ERROR = 'GET_STAFF_ERROR';

export const GET_HOW_TO_APPLY_START = 'GET_HOW_TO_APPLY_START';
export const GET_HOW_TO_APPLY_SUCCESS = 'GET_HOW_TO_APPLY_SUCCESS';
export const GET_HOW_TO_APPLY_ERROR = 'GET_HOW_TO_APPLY_ERROR';

export const GET_CARD_APPLY_START = 'GET_CARD_APPLY_START';
export const GET_CARD_APPLY_SUCCESS = 'GET_CARD_APPLY_SUCCESS';
export const GET_CARD_APPLY_ERROR = 'GET_CARD_APPLY_ERROR';

export const GET_INFO_APPLY_START = 'GET_INFO_APPLY_START';
export const GET_INFO_APPLY_SUCCESS = 'GET_INFO_APPLY_SUCCESS';
export const GET_INFO_APPLY_ERROR = 'GET_INFO_APPLY_ERROR';

export const GET_INFO_TALENT_START = 'GET_INFO_TALENT_START';
export const GET_INFO_TALENT_SUCCESS = 'GET_INFO_TALENT_SUCCESS';
export const GET_INFO_TALENT_ERROR = 'GET_INFO_TALENT_ERROR';

//

export const POST_ABOUT_US_START = 'POST_ABOUT_US_START';
export const POST_ABOUT_US_SUCCESS = 'POST_ABOUT_US_SUCCESS';
export const POST_ABOUT_US_ERROR = 'POST_ABOUT_US_ERROR';

export const POST_BANNERS_START = 'POST_BANNERS_START';
export const POST_BANNERS_SUCCESS = 'POST_BANNERS_SUCCESS';
export const POST_BANNERS_ERROR = 'POST_BANNERS_ERROR';

export const POST_FAQ_START = 'POST_FAQ_START';
export const POST_FAQ_SUCCESS = 'POST_FAQ_SUCCESS';
export const POST_FAQ_ERROR = 'POST_FAQ_ERROR';

export const POST_INFO_CARDS_START = 'POST_INFO_CARDS_START';
export const POST_INFO_CARDS_SUCCESS = 'POST_INFO_CARDS_SUCCESS';
export const POST_INFO_CARDS_ERROR = 'POST_INFO_CARDS_ERROR';

export const POST_LIST_ITEMS_START = 'POST_LIST_ITEMS_START';
export const POST_LIST_ITEMS_SUCCESS = 'POST_LIST_ITEMS_SUCCESS';
export const POST_LIST_ITEMS_ERROR = 'POST_LIST_ITEMS_ERROR';

export const POST_OUR_WORK_START = 'POST_OUR_WORK_START';
export const POST_OUR_WORK_SUCCESS = 'POST_OUR_WORK_SUCCESS';
export const POST_OUR_WORK_ERROR = 'POST_OUR_WORK_ERROR';

export const POST_ENTERPRISES_START = 'POST_ENTERPRISES_START';
export const POST_ENTERPRISES_SUCCESS = 'POST_ENTERPRISES_SUCCESS';
export const POST_ENTERPRISES_ERROR = 'POST_ENTERPRISES_ERROR';

export const POST_FOUNDERS_START = 'POST_FOUNDERS_START';
export const POST_FOUNDERS_SUCCESS = 'POST_FOUNDERS_SUCCESS';
export const POST_FOUNDERS_ERROR = 'POST_FOUNDERS_ERROR';

export const POST_PORTOFOLIO_START = 'POST_PORTOFOLIO_START';
export const POST_PORTOFOLIO_SUCCESS = 'POST_PORTOFOLIO_SUCCESS';
export const POST_PORTOFOLIO_ERROR = 'POST_PORTOFOLIO_ERROR';

export const POST_STAFF_CARDS_START = 'POST_STAFF_CARDS_START';
export const POST_STAFF_CARDS_SUCCESS = 'POST_STAFF_CARDS_SUCCESS';
export const POST_STAFF_CARDS_ERROR = 'POST_STAFF_CARDS_ERROR';

export const POST_STAFF_START = 'POST_STAFF_START';
export const POST_STAFF_SUCCESS = 'POST_STAFF_SUCCESS';
export const POST_STAFF_ERROR = 'POST_STAFF_ERROR';

export const POST_HOW_TO_APPLY_START = 'POST_HOW_TO_APPLY_START';
export const POST_HOW_TO_APPLY_SUCCESS = 'POST_HOW_TO_APPLY_SUCCESS';
export const POST_HOW_TO_APPLY_ERROR = 'POST_HOW_TO_APPLY_ERROR';

export const POST_CARD_APPLY_START = 'POST_CARD_APPLY_START';
export const POST_CARD_APPLY_SUCCESS = 'POST_CARD_APPLY_SUCCESS';
export const POST_CARD_APPLY_ERROR = 'POST_CARD_APPLY_ERROR';

export const POST_INFO_APPLY_START = 'POST_INFO_APPLY_START';
export const POST_INFO_APPLY_SUCCESS = 'POST_INFO_APPLY_SUCCESS';
export const POST_INFO_APPLY_ERROR = 'POST_INFO_APPLY_ERROR';

export const POST_INFO_TALENT_START = 'POST_INFO_TALENT_START';
export const POST_INFO_TALENT_SUCCESS = 'POST_INFO_TALENT_SUCCESS';
export const POST_INFO_TALENT_ERROR = 'POST_INFO_TALENT_ERROR';

//

export const PUT_ABOUT_US_START = 'PUT_ABOUT_US_START';
export const PUT_ABOUT_US_SUCCESS = 'PUT_ABOUT_US_SUCCESS';
export const PUT_ABOUT_US_ERROR = 'PUT_ABOUT_US_ERROR';

export const PUT_BANNERS_START = 'PUT_BANNERS_START';
export const PUT_BANNERS_SUCCESS = 'PUT_BANNERS_SUCCESS';
export const PUT_BANNERS_ERROR = 'PUT_BANNERS_ERROR';

export const PUT_FAQ_START = 'PUT_FAQ_START';
export const PUT_FAQ_SUCCESS = 'PUT_FAQ_SUCCESS';
export const PUT_FAQ_ERROR = 'PUT_FAQ_ERROR';

export const PUT_INFO_CARDS_START = 'PUT_INFO_CARDS_START';
export const PUT_INFO_CARDS_SUCCESS = 'PUT_INFO_CARDS_SUCCESS';
export const PUT_INFO_CARDS_ERROR = 'PUT_INFO_CARDS_ERROR';

export const PUT_LIST_ITEMS_START = 'PUT_LIST_ITEMS_START';
export const PUT_LIST_ITEMS_SUCCESS = 'PUT_LIST_ITEMS_SUCCESS';
export const PUT_LIST_ITEMS_ERROR = 'PUT_LIST_ITEMS_ERROR';

export const PUT_OUR_WORK_START = 'PUT_OUR_WORK_START';
export const PUT_OUR_WORK_SUCCESS = 'PUT_OUR_WORK_SUCCESS';
export const PUT_OUR_WORK_ERROR = 'PUT_OUR_WORK_ERROR';

export const PUT_ENTERPRISES_START = 'PUT_ENTERPRISES_START';
export const PUT_ENTERPRISES_SUCCESS = 'PUT_ENTERPRISES_SUCCESS';
export const PUT_ENTERPRISES_ERROR = 'PUT_ENTERPRISES_ERROR';

export const PUT_FOUNDERS_START = 'PUT_FOUNDERS_START';
export const PUT_FOUNDERS_SUCCESS = 'PUT_FOUNDERS_SUCCESS';
export const PUT_FOUNDERS_ERROR = 'PUT_FOUNDERS_ERROR';

export const PUT_PORTOFOLIO_START = 'PUT_PORTOFOLIO_START';
export const PUT_PORTOFOLIO_SUCCESS = 'PUT_PORTOFOLIO_SUCCESS';
export const PUT_PORTOFOLIO_ERROR = 'PUT_PORTOFOLIO_ERROR';

export const PUT_STAFF_CARDS_START = 'PUT_STAFF_CARDS_START';
export const PUT_STAFF_CARDS_SUCCESS = 'PUT_STAFF_CARDS_SUCCESS';
export const PUT_STAFF_CARDS_ERROR = 'PUT_STAFF_CARDS_ERROR';

export const PUT_STAFF_START = 'PUT_STAFF_START';
export const PUT_STAFF_SUCCESS = 'PUT_STAFF_SUCCESS';
export const PUT_STAFF_ERROR = 'PUT_STAFF_ERROR';

export const PUT_HOW_TO_APPLY_START = 'PUT_HOW_TO_APPLY_START';
export const PUT_HOW_TO_APPLY_SUCCESS = 'PUT_HOW_TO_APPLY_SUCCESS';
export const PUT_HOW_TO_APPLY_ERROR = 'PUT_HOW_TO_APPLY_ERROR';

export const PUT_CARD_APPLY_START = 'PUT_CARD_APPLY_START';
export const PUT_CARD_APPLY_SUCCESS = 'PUT_CARD_APPLY_SUCCESS';
export const PUT_CARD_APPLY_ERROR = 'PUT_CARD_APPLY_ERROR';

export const PUT_INFO_APPLY_START = 'PUT_INFO_APPLY_START';
export const PUT_INFO_APPLY_SUCCESS = 'PUT_INFO_APPLY_SUCCESS';
export const PUT_INFO_APPLY_ERROR = 'PUT_INFO_APPLY_ERROR';

export const PUT_INFO_TALENT_START = 'PUT_INFO_TALENT_START';
export const PUT_INFO_TALENT_SUCCESS = 'PUT_INFO_TALENT_SUCCESS';
export const PUT_INFO_TALENT_ERROR = 'PUT_INFO_TALENT_ERROR';

//

export const DELETE_ABOUT_US_START = 'DELETE_ABOUT_US_START';
export const DELETE_ABOUT_US_SUCCESS = 'DELETE_ABOUT_US_SUCCESS';
export const DELETE_ABOUT_US_ERROR = 'DELETE_ABOUT_US_ERROR';

export const DELETE_BANNERS_START = 'DELETE_BANNERS_START';
export const DELETE_BANNERS_SUCCESS = 'DELETE_BANNERS_SUCCESS';
export const DELETE_BANNERS_ERROR = 'DELETE_BANNERS_ERROR';

export const DELETE_FAQ_START = 'DELETE_FAQ_START';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_ERROR = 'DELETE_FAQ_ERROR';

export const DELETE_INFO_CARDS_START = 'DELETE_INFO_CARDS_START';
export const DELETE_INFO_CARDS_SUCCESS = 'DELETE_INFO_CARDS_SUCCESS';
export const DELETE_INFO_CARDS_ERROR = 'DELETE_INFO_CARDS_ERROR';

export const DELETE_LIST_ITEMS_START = 'DELETE_LIST_ITEMS_START';
export const DELETE_LIST_ITEMS_SUCCESS = 'DELETE_LIST_ITEMS_SUCCESS';
export const DELETE_LIST_ITEMS_ERROR = 'DELETE_LIST_ITEMS_ERROR';

export const DELETE_OUR_WORK_START = 'DELETE_OUR_WORK_START';
export const DELETE_OUR_WORK_SUCCESS = 'DELETE_OUR_WORK_SUCCESS';
export const DELETE_OUR_WORK_ERROR = 'DELETE_OUR_WORK_ERROR';

export const DELETE_ENTERPRISES_START = 'DELETE_ENTERPRISES_START';
export const DELETE_ENTERPRISES_SUCCESS = 'DELETE_ENTERPRISES_SUCCESS';
export const DELETE_ENTERPRISES_ERROR = 'DELETE_ENTERPRISES_ERROR';

export const DELETE_FOUNDERS_START = 'DELETE_FOUNDERS_START';
export const DELETE_FOUNDERS_SUCCESS = 'DELETE_FOUNDERS_SUCCESS';
export const DELETE_FOUNDERS_ERROR = 'DELETE_FOUNDERS_ERROR';

export const DELETE_PORTOFOLIO_START = 'DELETE_PORTOFOLIO_START';
export const DELETE_PORTOFOLIO_SUCCESS = 'DELETE_PORTOFOLIO_SUCCESS';
export const DELETE_PORTOFOLIO_ERROR = 'DELETE_PORTOFOLIO_ERROR';

export const DELETE_STAFF_CARDS_START = 'DELETE_STAFF_CARDS_START';
export const DELETE_STAFF_CARDS_SUCCESS = 'DELETE_STAFF_CARDS_SUCCESS';
export const DELETE_STAFF_CARDS_ERROR = 'DELETE_STAFF_CARDS_ERROR';

export const DELETE_STAFF_START = 'DELETE_STAFF_START';
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS';
export const DELETE_STAFF_ERROR = 'DELETE_STAFF_ERROR';

export const DELETE_HOW_TO_APPLY_START = 'DELETE_HOW_TO_APPLY_START';
export const DELETE_HOW_TO_APPLY_SUCCESS = 'DELETE_HOW_TO_APPLY_SUCCESS';
export const DELETE_HOW_TO_APPLY_ERROR = 'DELETE_HOW_TO_APPLY_ERROR';

export const DELETE_CARD_APPLY_START = 'DELETE_CARD_APPLY_START';
export const DELETE_CARD_APPLY_SUCCESS = 'DELETE_CARD_APPLY_SUCCESS';
export const DELETE_CARD_APPLY_ERROR = 'DELETE_CARD_APPLY_ERROR';

export const DELETE_INFO_APPLY_START = 'DELETE_INFO_APPLY_START';
export const DELETE_INFO_APPLY_SUCCESS = 'DELETE_INFO_APPLY_SUCCESS';
export const DELETE_INFO_APPLY_ERROR = 'DELETE_INFO_APPLY_ERROR';

export const DELETE_INFO_TALENT_START = 'DELETE_INFO_TALENT_START';
export const DELETE_INFO_TALENT_SUCCESS = 'DELETE_INFO_TALENT_SUCCESS';
export const DELETE_INFO_TALENT_ERROR = 'DELETE_INFO_TALENT_ERROR';
