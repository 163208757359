import { DELETE_INFO_APPLY_ERROR, DELETE_INFO_APPLY_START, DELETE_INFO_APPLY_SUCCESS } from '../../types';

export default function delete_info_apply(state, action) {
  switch (action.type) {
    case DELETE_INFO_APPLY_START:
      return { ...state };
    case DELETE_INFO_APPLY_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case DELETE_INFO_APPLY_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}