import { takeLatest, call, put } from 'redux-saga/effects';
import { DELETE_HOW_TO_APPLY_ERROR, DELETE_HOW_TO_APPLY_START, DELETE_HOW_TO_APPLY_SUCCESS, GET_HOW_TO_APPLY_START } from '../../types';

import apiCall from '../../../api/apiGet';

export function* del({ payload }) {
  try {
    const url = `/how-to-apply/delete/${payload.id}`;
    const method = 'DELETE';
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, headers);
    console.log(results)
    yield put({ type: DELETE_HOW_TO_APPLY_SUCCESS, results: results.data });
  } catch (error) {
    console.log(error)
    yield put({ type: DELETE_HOW_TO_APPLY_ERROR, error });
  } finally {
    yield put({ type: GET_HOW_TO_APPLY_START, payload: {lang: null, token: payload.token} });
    payload.navigate(-1);
  }
}

export default function* delete_how_to_apply() {
  yield takeLatest(DELETE_HOW_TO_APPLY_START, del);
}
