import { DELETE_STAFF_CARDS_ERROR, DELETE_STAFF_CARDS_START, DELETE_STAFF_CARDS_SUCCESS } from '../../types';

export default function delete_staff_cards(state, action) {
  switch (action.type) {
    case DELETE_STAFF_CARDS_START:
      return { ...state };
    case DELETE_STAFF_CARDS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case DELETE_STAFF_CARDS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}