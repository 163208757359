import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_ENTERPRISES_ERROR, GET_ENTERPRISES_START, GET_ENTERPRISES_SUCCESS } from '../../types';

import apiCall from '../../../api/apiGet';

export function* get({ payload }) {
  try {
    const url = payload.lang ? `/enterprises/${payload.lang}` : '/enterprises/';
    const method = 'GET';
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, headers);
    console.log(results)
    yield put({ type: GET_ENTERPRISES_SUCCESS, results: results.data });

  } catch (error) {
    console.log(error)
    yield put({ type: GET_ENTERPRISES_ERROR, error });
  } 
}

export default function* get_enterprises() {
  yield takeLatest(GET_ENTERPRISES_START, get);
}
