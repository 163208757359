import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  isActive: boolean
  lang: string
  full_name: string
  job_position: string
  image: string
  comment?: string
  linkedin: string
  enterprise: string
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  isActive: true,
  lang: '',
  full_name: '',
  job_position: '',
  image: '',
  comment: '',
  linkedin: '',
  enterprise: '',
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        isActive: data.isActive || false,
        lang: data.lang || initialData.lang,
        full_name: data.full_name || initialData.full_name,
        job_position: data.job_position || initialData.job_position,
        comment: data.comment || initialData.comment,
        linkedin: data.linkedin || initialData.linkedin,
        image: data.image || initialData.image,
        enterprise: data.enterprise || initialData.enterprise,
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
