import { Navigate, Outlet, RouteObject } from 'react-router'
import { MainLayout } from '../layouts'
import {
  Login,
  Dashboard,
  ManageAboutUs,
  ManageOurWork,
  ManageBanners,
  ManageFaqs,
  CreateBanner,
  ManageInfoCards,
  ManageListItems,
  ManageEnterprises,
  CreateFaqs,
  CreateAboutUs,
  CreateInfoCards,
  CreateListItems,
  CreateOurWork,
  CreatePortofolio,
  CreateStaff,
  CreateEnterprises,
  CreateStaffTeam,
  ManageStaffTeam,
  CreateFounders,
  ManageFounders,
  CreateHowToApply,
  ManageHowToApply,
  ManageInfoApply,
  CreateInfoApply,
  ManageInfoTalent,
  CreateInfoTalent,
  ManageCardApply,
  CreateCardApply,
} from '../pages'
import { Text } from '../components/atoms'
import ManagePortofolio from '../pages/app/ManagePortofolio'
import ManageStaff from '../pages/app/ManageStaff'

type RoutesMap = (isLoggedIn: boolean) => RouteObject[]

export const routesMap: RoutesMap = (isLoggedIn: boolean) => [
  {
    path: 'app',
    element: isLoggedIn ? <MainLayout /> : <Navigate to="/login" />,
    children: [
      { path: 'dashboard', element: <Dashboard /> },
      {
        path: 'content-management',
        element: <Outlet />,
        children: [
          { path: 'manage-banners', element: <ManageBanners /> },
          { path: 'manage-about-us', element: <ManageAboutUs /> },
          { path: 'manage-our-work', element: <ManageOurWork /> },
          { path: 'manage-portofolio', element: <ManagePortofolio /> },
          { path: 'manage-faqs', element: <ManageFaqs /> },
          { path: 'manage-info-cards', element: <ManageInfoCards /> },
          { path: 'manage-list-items', element: <ManageListItems /> },
          { path: 'manage-enterprises', element: <ManageEnterprises /> },
          { path: 'manage-staff', element: <ManageStaff /> },
          { path: 'manage-staff-team', element: <ManageStaffTeam /> },
          { path: 'manage-founders', element: <ManageFounders /> },
          { path: 'manage-how-to-apply', element: <ManageHowToApply /> },
          { path: 'manage-info-apply', element: <ManageInfoApply /> },
          { path: 'manage-info-talent', element: <ManageInfoTalent /> },
          { path: 'manage-card-apply', element: <ManageCardApply /> },
          //{ path: 'create-page', element: <Outlet /> },
          { path: 'create-banners/:id', element: <CreateBanner /> },
          { path: 'create-banners', element: <CreateBanner /> },
          { path: 'create-faqs/:id', element: <CreateFaqs /> },
          { path: 'create-faqs', element: <CreateFaqs /> },
          { path: 'create-about-us/:id', element: <CreateAboutUs /> },
          { path: 'create-about-us', element: <CreateAboutUs /> },
          { path: 'create-info-cards/:id', element: <CreateInfoCards /> },
          { path: 'create-info-cards', element: <CreateInfoCards /> },
          { path: 'create-list-items/:id', element: <CreateListItems /> },
          { path: 'create-list-items', element: <CreateListItems /> },
          { path: 'create-our-work/:id', element: <CreateOurWork /> },
          { path: 'create-our-work', element: <CreateOurWork /> },
          { path: 'create-portofolio/:id', element: <CreatePortofolio /> },
          { path: 'create-portofolio', element: <CreatePortofolio /> },
          { path: 'create-staff/:id', element: <CreateStaff /> },
          { path: 'create-staff', element: <CreateStaff /> },
          { path: 'create-staff-team/:id', element: <CreateStaffTeam /> },
          { path: 'create-staff-team', element: <CreateStaffTeam /> },
          { path: 'create-enterprises/:id', element: <CreateEnterprises /> },
          { path: 'create-enterprises', element: <CreateEnterprises /> },
          { path: 'create-founders/:id', element: <CreateFounders /> },
          { path: 'create-founders', element: <CreateFounders /> },
          { path: 'create-how-to-apply/:id', element: <CreateHowToApply /> },
          { path: 'create-how-to-apply', element: <CreateHowToApply /> },
          { path: 'create-info-apply/:id', element: <CreateInfoApply /> },
          { path: 'create-info-apply', element: <CreateInfoApply /> },
          { path: 'create-info-talent/:id', element: <CreateInfoTalent /> },
          { path: 'create-info-talent', element: <CreateInfoTalent /> },
          { path: 'create-card-apply/:id', element: <CreateCardApply /> },
          { path: 'create-card-apply', element: <CreateCardApply /> },
        ],
      },
      {
        path: 'service-management',
        element: <Outlet />,
        children: [
          // { path: 'manage-fcm', element: <ManageFCM /> },
        ]
      },
      {
        path: 'users',
        element: <Outlet />,
        children: [
          // { path: 'list', element: <ManageUsers /> },
        ]
      },
      // { path: 'roles-management', element: <ManageRoles /> },
      { path: '/app', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Text textType='h3'>Continuamos trabajando</Text> },
    ],
  },
  {
    path: '/',
    element: !isLoggedIn ? <Outlet /> : <Navigate to="/app/dashboard" />,
    children: [
      { path: 'login', element: <Login /> },
      { path: '/', element: <Navigate to="/login" /> },
    ],
  },
]
