import { PUT_LIST_ITEMS_ERROR, PUT_LIST_ITEMS_START, PUT_LIST_ITEMS_SUCCESS } from '../../types';

export default function put_list_items(state, action) {
  switch (action.type) {
    case PUT_LIST_ITEMS_START:
      return { ...state };
    case PUT_LIST_ITEMS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case PUT_LIST_ITEMS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}