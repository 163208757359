/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from "../../../context/FaqsContext";
import { post_faqs } from '../../../redux/actions/faqs/post_faqs';
import { put_faqs } from '../../../redux/actions/faqs/put_faqs';
import { delete_faqs } from '../../../redux/actions/faqs/delete_faqs';
import { get_faqs } from '../../../redux/actions/faqs/get_faqs';
import Modal from '../Modal/index';


const FaqsPreview: React.FC = () => {
  const dispatch = useDispatch()
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const { formData } = useContext(Context)
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)


  const handleChange = (token: any, formData: any) => {
    if (id) {
      console.log(id)
      dispatch(put_faqs({ id, token, data: formData, navigate }))
    } else {
      delete formData.id
      dispatch(post_faqs({ token, data: formData, navigate }))
    }
  }

  const handleDelete = () => {
    dispatch(delete_faqs({ id, token, data: formData, navigate }))
    dispatch(get_faqs({ lang: null, token }))
  }


  const successAction = () => {
    handleChange(token, formData)
    dispatch(get_faqs({ lang: null, token }))
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModalDelete = () => {
    setShowModalDelete(false)
  }



  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.question}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.answer}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.lang}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => {
        setShowModal(true)
      }} disabled={!formData.question || !formData.lang || !formData.answer}>
        {!id ? 'Publicar' : 'Guardar'} Preguntas frecuentes
      </Button>

      {
        id ?
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de modificar esta pregunta?'}
              text={' '}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal} />
          ) :
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de publicar esta pregunta?'}
              text={''}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal}
            />
          )
      }


      {id && (
        <>
          <Button onClick={() => {
            setShowModalDelete(true)
          }}>
            Eliminar pregunta
          </Button>
          <Modal
            show={showModalDelete}
            title={'¿Esta seguro de eliminar esta pregunta?'}
            text={' '}
            onClickSuccess={handleDelete}
            onClickClose={closeModalDelete}
            btnSuccess={'Si'}
            btnWarning={"No"}
            onClickWarning={closeModalDelete}
          />
        </>
      )}
    </ContentSection>
  )
}


export default FaqsPreview;
