import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_STAFF_START, PUT_STAFF_ERROR, PUT_STAFF_START, PUT_STAFF_SUCCESS } from '../../types';

import apiCall from '../../../api';

export function* edit({ payload }) {
  try {
    const url = `/staff/update/${payload.id}`;
    const method = 'PUT';
    const data = payload.data;
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, data, headers);
    console.log(results)
    yield put({ type: PUT_STAFF_SUCCESS, results: results.data });
  } catch (error) {
    console.log(error)
    yield put({ type: PUT_STAFF_ERROR, error });
  } finally {
    yield put({ type: GET_STAFF_START, payload: {lang: null, token: payload.token} });
    payload.navigate(-1);
  }
}

export default function* put_staff() {
  yield takeLatest(PUT_STAFF_START, edit);
}
