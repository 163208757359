/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from "../../../context/EnterprisesContext";
import { post_enterprises } from '../../../redux/actions/enterprises/post_enterprises';
import { put_enterprises } from '../../../redux/actions/enterprises/put_enterprises';
import { delete_enterprises } from '../../../redux/actions/enterprises/delete_enterprises';
//import { get_enterprises } from '../../../redux/actions/enterprises/get_enterprises';
import Modal from '../Modal/index';

const EnterprisePreview: React.FC = () => {
  const dispatch = useDispatch()
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const { formData } = useContext(Context)
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)


  const handleChange = (token: any, formData: any) => {
    if (id) {
      console.log(id)
      dispatch(put_enterprises({ id, token, data: formData, navigate }))
    } else {
      delete formData.id
      dispatch(post_enterprises({ token, data: formData, navigate }))
    }
  }

  const handleDelete = () => {
    dispatch(delete_enterprises({ id, token, data: formData, navigate }))
    //dispatch(get_banners({ lang: null, token }))
  }


  const successAction = () => {
    handleChange(token, formData)
    //dispatch(get_banners({ lang: null, token }))
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModalDelete = () => {
    setShowModalDelete(false)
  }


  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          {
            formData.logo &&
            (<img
              src={formData.logo}
              alt='placeholder'
              css={styles.previewImage}
            />)
          }

          {
            formData.image_card &&
            (
              <img
                src={formData.image_card}
                alt='placeholder'
                css={styles.previewImage}
              />
            )
          }
          {
            formData.banner &&
            (
              <img
                src={formData.banner}
                alt='placeholder'
                css={styles.previewImage}
              />
            )
          }

          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.name}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.linkedin}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.facebook}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.instragram}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.url}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.href}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.portofolio}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.short_description}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.large_description}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.lang}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => {
        setShowModal(true)
      }} disabled={!formData.title || !formData.name
        || !formData.lang || !formData.short_description || !formData.large_description || !formData.banner
        || !formData.image_card || !formData.logo || !formData.linkedin || !formData.facebook || !formData.instragram
        || !formData.url || !formData.portofolio || !formData.href}>
        {!id ? 'Publicar' : 'Guardar'} Empresa
      </Button>

      {
        id ?
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de modificar esta empresa?'}
              text={' '}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal} />
          ) :
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de publicar esta empresa?'}
              text={''}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal}
            />
          )
      }



      {id && (
        <>
          <Button onClick={() => {
            setShowModalDelete(true)
          }}>
            Eliminar empresa
          </Button>
          <Modal
            show={showModalDelete}
            title={'¿Esta seguro de eliminar esta empresa?'}
            text={' '}
            onClickSuccess={handleDelete}
            onClickClose={closeModalDelete}
            btnSuccess={'Si'}
            btnWarning={"No"}
            onClickWarning={closeModalDelete}
          />
        </>
      )}
    </ContentSection>
  )
}


export default EnterprisePreview;
