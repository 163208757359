import { css } from '@emotion/react';
export const background = css`
  //background-image: url('/bg.png');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  height: 100vh;
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
`
export const sideSection = css`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  padding: 59px;
  max-width: 472px;
  height: 100%;
  background-color: transparent;
  backdrop-filter: blur(34px);
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
