import { DELETE_STAFF_ERROR, DELETE_STAFF_START, DELETE_STAFF_SUCCESS } from '../../types';

export default function delete_staff(state, action) {
  switch (action.type) {
    case DELETE_STAFF_START:
      return { ...state };
    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case DELETE_STAFF_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}
