import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  isActive: boolean
  lang: string
  title: string
  name: string
  short_description: string
  large_description: string
  logo: string
  image_card: string
  banner: string
  linkedin: string
  facebook: string
  instragram: string
  url: string
  href: string
  portofolio: string
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  isActive: true,
  lang: '',
  title: '',
  name: '',
  short_description: '',
  large_description: '',
  logo: '',
  image_card: '',
  banner: '',
  linkedin: '',
  facebook: '',
  instragram: '',
  url: '',
  portofolio: '',
  href: '',
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        isActive: data.isActive || false,
        lang: data.lang || initialData.lang,
        title: data.title || initialData.title,
        name: data.name || initialData.name,
        short_description: data.short_description || initialData.short_description,
        large_description: data.large_description || initialData.large_description,
        logo: data.logo || initialData.logo,
        image_card: data.image_card || initialData.image_card,
        banner: data.banner || initialData.banner,
        linkedin: data.linkedin || initialData.linkedin,
        facebook: data.facebook || initialData.facebook,
        instragram: data.instragram || initialData.instragram,
        url: data.url || initialData.url,
        href: data.href || initialData.href,
        portofolio: data.portofolio || initialData.portofolio,
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
