import { RouteSection } from '../components/organisms/NavSection/types'
import { DocumentIcon } from './icons'
//import { AddUserIcon, HomeIcon, UserIcon } from './icons'

const navListRoutes: RouteSection[] = [
  // {
  //   children: [
  //     {
  //       text: 'Dashboard',
  //       path: '/app/dashboard',
  //       icon: HomeIcon,
  //     }
  //   ],
  // },
  {
    title: 'Administración de Contenidos',
    children: [
      {
        text: 'Home',
        path: '/app/content-management',
        icon: DocumentIcon,
        children: [
          {
            text: ' Banner principal',
            path: '/app/content-management/manage-banners',
          },
          {
            text: ' Secciones intermedias',
            path: '/app/content-management/manage-info-cards',
          },

          {
            text: 'Quiero aplicar',
            path: '/app/content-management/manage-info-apply',
          },
          {
            text: ' Bloque de texto',
            path: '/app/content-management/manage-info-talent',
          },
          {
            text: 'lista de items',
            path: '/app/content-management/manage-list-items',
          },
        ],
      },
      {
        text: 'Quiénes somos',
        path: '/app/content-management',
        icon: DocumentIcon,
        children: [
          {
            text: ' ¿Quiénes somos?',
            path: '/app/content-management/manage-about-us',
          },
          {
            text: ' Staff ',
            path: '/app/content-management/manage-staff',
          },
          {
            text: ' Staff - integrantes',
            path: '/app/content-management/manage-staff-team',
          },
        ],
      },
      {
        text: ' Qué hacemos',
        path: '/app/content-management',
        icon: DocumentIcon,
        children: [
          {
            text: 'Qué hacemos',
            path: '/app/content-management/manage-our-work',
          },
        ],
      },
      {
        text: ' Portafolio',
        path: '/app/content-management',
        icon: DocumentIcon,
        children: [
          {
            text: ' Portafolio',
            path: '/app/content-management/manage-portofolio',
          },
          {
            text: ' Empresas',
            path: '/app/content-management/manage-enterprises',
          },
          {
            text: ' Fundadores ',
            path: '/app/content-management/manage-founders',
          },
        ],
      },
      {
        text: 'Cómo aplicar',
        path: '/app/content-management',
        icon: DocumentIcon,
        children: [
          {
            text: ' Cómo aplicar',
            path: '/app/content-management/manage-how-to-apply',
          },
          {
            text: ' Tarjetas de como aplicar',
            path: '/app/content-management/manage-card-apply',
          },
          {
            text: ' Preguntas Frecuentes',
            path: '/app/content-management/manage-faqs',
          },
        ],
      },
      /*
            {
              text: 'Administración de servicios',
              path: '/app/services-management',
              icon: ServiceIcon,
              children: [
                {
                  text: 'Administrar Preguntas frecuentes',
                  path: '/app/content-management/manage-faqs',
                },
              ],
            },
            /*
                  {
                    text: 'Administración de servicios',
                    path: '/app/services-management',
                    icon: ServiceIcon,
                    children: [
                      {
                        text: 'Enviar FCM (push notifications)',
                        path: '/app/service-management/manage-fcm',
                      },
                    ]
                  }
            */
    ]
  },
  // {
  //   title: 'Gestión de usuarios - CMS',
  //   children: [
  //     {
  //       text: 'Creacion de usuarios',
  //       path: '/app/users/list',
  //       icon: AddUserIcon,
  //     },
  //     {
  //       text: 'Administración de roles',
  //       path: '/app/roles-management',
  //       icon: UserIcon,
  //     }
  //   ]
  // },
]

export default navListRoutes
