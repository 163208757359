import { GET_FOUNDERS_ERROR, GET_FOUNDERS_START, GET_FOUNDERS_SUCCESS } from '../../types';

export default function get_founders(state, action) {
  switch (action.type) {
    case GET_FOUNDERS_START:
      return { ...state };
    case GET_FOUNDERS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case GET_FOUNDERS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}
