import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_OUR_WORK_START, PUT_OUR_WORK_ERROR, PUT_OUR_WORK_START, PUT_OUR_WORK_SUCCESS } from '../../types';

import apiCall from '../../../api';

export function* edit({ payload }) {
  try {
    const url = `/our-work/update/${payload.id}`;
    const method = 'PUT';
    const data = payload.data;
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, data, headers);
    console.log(results)
    yield put({ type: PUT_OUR_WORK_SUCCESS, results: results.data });
  } catch (error) {
    console.log(error)
    yield put({ type: PUT_OUR_WORK_ERROR, error });
  } finally {
    yield put({ type: GET_OUR_WORK_START, payload: {lang: null, token: payload.token} });
    payload.navigate(-1);
  }
}

export default function* put_our_work() {
  yield takeLatest(PUT_OUR_WORK_START, edit);
}
