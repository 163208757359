/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { useLocation } from "react-router-dom";
import { Breadcrumb } from '../../../components/atoms'
import { breadcrumbItems } from '../../utils'

const { index, contentManagement, infoApplyManagement } = breadcrumbItems

const ManageInfoApply: React.FC = () => {
  const navigate = useNavigate()
  let location = useLocation();
  const data = useSelector((state: any) => state.get_info_apply.data)

  useEffect(() => {
    // dispatch(banners({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.pathname === '/app/content-management/manage-info-cards') {
      setTimeout(() => {
        // dispatch(banners({ token }));
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, infoApplyManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Quiero aplicar"
            subtitle="Quiero aplicar - publicados"
            columns={columns}
            data={data ? data?.data : []}
            route={'/app/content-management/create-info-apply/'}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo - Quiero aplicar"
            message=""
            textButton="Crear nuevo"
            onCreate={() => navigate('/app/content-management/create-info-apply')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageInfoApply;
