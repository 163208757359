import { POST_BANNERS_ERROR, POST_BANNERS_START, POST_BANNERS_SUCCESS } from '../../types';

export default function post_banners(state, action) {
  switch (action.type) {
    case POST_BANNERS_START:
      return { ...state, loading: true };
    case POST_BANNERS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
        loading: false,
      };
    case POST_BANNERS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error,
        loading: false,
      };
    default:
      return { ...state };
  }
}
