import { combineReducers } from 'redux';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { LOGOUT } from '../types';

import language from "./language";
import login from './auth/login';
import get_about_us from "./about_us/get_about_us";
import get_banners from "./banners/get_banners";
import get_faqs from "./faqs/get_faqs";
import get_info_cards from "./info_cards/get_info_cards";
import get_list_items from "./list_items/get_list_items";
import get_our_work from "./our_work/get_our_work";
import get_enterprises from "./enterprises/get_enterprises";
import get_portofolio from "./portofolio/get_portofolio";
import get_staff from "./staff/get_staff";
import get_staff_cards from "./staff_cards/get_staff_cards";
import get_founders from "./founders/get_founders";
import get_how_to_apply from "./how_to_apply/get_how_to_apply";
import get_card_apply from "./card_apply/get_card_apply";
import get_info_apply from './info_apply/get_info_apply';
import get_info_talent from './info_talent/get_info_talent';

import post_about_us from './about_us/post_about_us';
import post_staff from './staff/post_staff';
import post_banners from './banners/post_banners';
import post_faqs from './faqs/post_faqs';
import post_info_cards from './info_cards/post_info_cards';
import post_list_items from './list_items/post_list_items';
import post_our_work from './our_work/post_our_work';
import post_enterprises from './enterprises/post_enterprises';
import post_portofolio from './portofolio/post_portofolio';
import post_staff_cards from './staff_cards/post_staff_cards';
import post_founders from './founders/post_founders';
import post_how_to_apply from './how_to_apply/post_how_to_apply';
import post_card_apply from './card_apply/post_card_apply';
import post_info_apply from './info_apply/post_info_apply';
import post_info_talent from './info_talent/post_info_talent';

import put_about_us from './about_us/put_about_us';
import put_banners from './banners/put_banners';
import put_faqs from './faqs/put_faqs';
import put_info_cards from './info_cards/put_info_cards';
import put_list_items from './list_items/put_list_items';
import put_our_work from './our_work/put_our_work';
import put_enterprises from './enterprises/put_enterprises';
import put_founders from './founders/put_founders';
import put_portofolio from './portofolio/put_portofolio';
import put_staff from './staff/put_staff';
import put_staff_cards from './staff_cards/put_staff_cards';
import put_how_to_apply from './how_to_apply/put_how_to_apply';
import put_card_apply from './card_apply/put_card_apply';
import put_info_apply from './info_apply/put_info_apply';
import put_info_talent from './info_talent/put_info_talent';

import delete_about_us from './about_us/delete_about_us';
import delete_banners from './banners/delete_banners';
import delete_faqs from './faqs/delete_faqs';
import delete_info_cards from './info_cards/delete_info_cards';
import delete_list_items from './list_items/delete_list_items';
import delete_our_work from './our_work/delete_our_work';
import delete_enterprises from './enterprises/delete_enterprises';
import delete_founders from './founders/delete_founders';
import delete_portofolio from './portofolio/delete_portofolio';
import delete_staff_cards from './staff_cards/delete_staff_cards';
import delete_staff from './staff/delete_staff';
import delete_how_to_apply from './how_to_apply/delete_how_to_apply';
import delete_card_apply from './card_apply/delete_card_apply';
import delete_info_apply from './info_apply/delete_info_apply';
import delete_info_talent from './info_talent/delete_info_talent';

const appReducer = combineReducers({
  language,
  login,
  get_about_us,
  get_banners,
  get_faqs,
  get_info_cards,
  get_list_items,
  get_our_work,
  get_enterprises,
  get_portofolio,
  get_staff,  
  get_staff_cards,
  get_founders,
  get_how_to_apply,
  get_card_apply,
  get_info_apply,
  get_info_talent,
  post_staff,
  post_about_us,
  post_banners,
  post_faqs,
  post_info_cards,
  post_list_items,
  post_our_work,
  post_enterprises,
  post_portofolio,
  post_staff_cards,
  post_founders,
  post_how_to_apply,
  post_card_apply,
  post_info_apply,
  post_info_talent,
  put_about_us,
  put_banners,
  put_faqs,
  put_info_cards,
  put_list_items,
  put_our_work,
  put_enterprises,
  put_founders,
  put_portofolio,
  put_staff,
  put_staff_cards,
  put_how_to_apply,
  put_card_apply,
  put_info_apply,
  put_info_talent,
  delete_about_us,
  delete_banners,
  delete_faqs,
  delete_info_cards,
  delete_list_items,
  delete_our_work,
  delete_enterprises,
  delete_founders,
  delete_portofolio,
  delete_staff_cards,
  delete_staff,
  delete_how_to_apply,
  delete_card_apply,
  delete_info_apply,
  delete_info_talent,
});

const rootReducer = (state, action) => {
  if (action.type === REHYDRATE) {
    return { ...state, persistedState: action.payload };
  }
  if (action.type === LOGOUT) {
    state = undefined;
    localStorage.removeItem('persist:root');
    localStorage.clear();
  } else {
    state = { ...state }
  }

  return appReducer(state, action);
};

export default rootReducer;
