import { css } from '@emotion/react'

export const breadcrumbItems = {
  index: { label: 'Inicio', path: '/' },
  dashboard: { label: 'Dashboard', path: '/app' },
  contentManagement: { label: 'Administración de contenidos' },
  aboutUsManagement: { label: '¿Quiénes somos?' },
  ourWorkManagement: { label: '¿Qué hacemos?' },
  portofolioManagement: { label: 'Portafolio' },
  howToApplyManagement: { label: '¿Cómo aplicar?' },
  bannersManagement: { label: 'Banners' },
  faqsManagement: { label: 'Preguntas Frecuentes' },
  infoCardsManagement: { label: 'Informacion' },
  listItemsManagement: { label: 'Elementos' },
  enterprisesManagement: { label: 'Empresas' },
  foundersManagement: { label: 'Fundadores' },
  staffManagement: { label: 'Personal' },
  staffTeamManagement: { label: 'Nuestro equipo' },
  infoApplyManagement: { label: 'información para aplicar' },
  infoTalentManagement: { label: 'información para talento' },
  cardApplyManagement: { label: 'targeta de información aplicar' },
  // serviceManagement: { label: 'Administración de servicios' },
  // users: { label: 'Usuarios' },
}

export const LANG_ALL = [
  { value: 'ES', label: 'ES' },
  { value: 'EN', label: 'EN' }
]

export const validURL = (str: string) => {
  var pattern = new RegExp('^(https?:\\/\\/)?' +
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    '(\\#[-a-z\\d_]*)?$', 'i');
  return !!pattern.test(str);
}

export const validateNumber = (data: string) => /^[0-9]+$/.test(data)

export const styleContainer = css`display: grid; gap: 18px;`;
export const styleContentSection = (title: any) => css`padding: ${title === "" ? "0 0 0 0" : ""};`;
export const styleBaseInput = (title: any) => css` max-width: 350px; self-justify: flex-end; display: ${title === "" ? "none" : "block"};`;
