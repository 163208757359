import React, {useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRoutes } from 'react-router-dom'
import i18n from 'i18next';
import { routesMap } from './router'

import './config-i18n';
import { setLanguage } from './redux/actions/language';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLogged = useSelector((state: any) => state.login.isLogged)
  const routes = useRoutes([...routesMap(isLogged)])
  const language = useSelector((state: any) => state.language)

  useEffect(() => {
    return () => {
      if (!language.language) {
        i18n.changeLanguage('es');
        dispatch(setLanguage('es'))
      } else {
        i18n.changeLanguage(language.language);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <React.Fragment>{routes}</React.Fragment>
}

export default App
