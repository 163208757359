import { DELETE_FAQ_ERROR, DELETE_FAQ_START, DELETE_FAQ_SUCCESS } from '../../types';

export default function delete_faqs(state, action) {
  switch (action.type) {
    case DELETE_FAQ_START:
      return { ...state };
    case DELETE_FAQ_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case DELETE_FAQ_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}