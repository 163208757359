/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
//import { useLocation } from "react-router-dom";
import { Breadcrumb } from '../../../components/atoms'
import { breadcrumbItems } from '../../utils'

const { index, contentManagement, faqsManagement } = breadcrumbItems

const ManageFaqs: React.FC = () => {
  const navigate = useNavigate()
  //let location = useLocation();
  const data = useSelector((state: any) => state.get_faqs.data)
  //const token = useSelector((state: any) => state.login.access)

  // useEffect(() => {
  //   if (location.pathname === '/app/content-management/manage-faqs') {
  //     console.log('true')
  //     setTimeout(() => {
  //       dispatch(get_faqs({ lang: null, token }));
  //     }, 1500)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, faqsManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar - Preguntas Frecuentes"
            subtitle="Preguntas Frecuentes - publicados"
            columns={columns}
            data={data ? data?.data : []}
            route={'/app/content-management/create-faqs/'}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo - Pregunta Frecuente"
            message=""
            textButton="Crear nuevo"
            onCreate={() => navigate('/app/content-management/create-faqs')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageFaqs;