import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_INFO_TALENT_ERROR, GET_INFO_TALENT_START, GET_INFO_TALENT_SUCCESS } from '../../types';

import apiCall from '../../../api/apiGet';

export function* get({ payload }) {
  try {
    const url = payload.lang ? `/info-talent/${payload.lang}` : '/info-talent/';
    const method = 'GET';
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, headers);
    console.log(results)
    yield put({ type: GET_INFO_TALENT_SUCCESS, results: results.data });

  } catch (error) {
    console.log(error)
    yield put({ type: GET_INFO_TALENT_ERROR, error });
  }
}

export default function* get_info_talent() {
  yield takeLatest(GET_INFO_TALENT_START, get);
}
