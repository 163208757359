import { takeLatest, put } from 'redux-saga/effects';
import { SET_LANGUAGE_SUCCESS, SET_LANGUAGE_ERROR, SET_LANGUAGE_START } from '../types';

export function* setLanguage({ payload }) {
  try {
    yield put({ type: SET_LANGUAGE_SUCCESS, results: payload });
  } catch (error) {
    yield put({ type: SET_LANGUAGE_ERROR, error });
  }
}

export default function* language() {
  yield takeLatest(SET_LANGUAGE_START, setLanguage);
}
