import { GET_STAFF_ERROR, GET_STAFF_START, GET_STAFF_SUCCESS } from '../../types';

export default function get_staff(state, action) {
  switch (action.type) {
    case GET_STAFF_START:
      return { ...state };
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case GET_STAFF_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}
