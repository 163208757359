import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from '../reducers';
import sagas from '../sagas';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: [
    'language',
    'login',
    'get_about_us',
    'get_banners',
    'get_faqs',
    'get_info_cards',
    'get_list_items',
    'get_our_work',
    'get_enterprises',
    'get_portofolio',
    'get_staff_cards',
    'get_founders',
    'get_how_to_apply',
    'get_card_apply',
    'get_info_apply',
    'get_info_talent',
    'post_staff',
    'post_about_us',
    'post_banners',
    'post_faqs',
    'post_info_cards',
    'post_list_items',
    'post_our_work',
    'post_enterprises',
    'post_portofolio',
    'post_staff_cards',
    'post_founders',
    'post_how_to_apply',
    'post_card_apply',
    'post_info_apply',
    'post_info_talent',
    'put_about_us',
    'put_banners',
    'put_faqs',
    'put_info_cards',
    'put_list_items',
    'put_our_work',
    'put_enterprises',
    'put_founders',
    'put_staff_cards',
    'put_staff',
    'put_how_to_apply',
    'put_card_apply',
    'put_info_apply',
    'put_info_talent',
    'delete_about_us',
    'delete_banners',
    'delete_faqs',
    'delete_info_cards',
    'delete_list_items',
    'delete_our_work',
    'delete_enterprises',
    'delete_founders',
    'delete_staff_cards',
    'delete_staff',
    'delete_how_to_apply',
    'delete_card_apply',
    'delete_info_apply',
    'delete_info_talent',
  ],
  timeout: 0,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(sagaMiddleware),
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()) ||
    compose,
  ),
);

sagaMiddleware.run(sagas);

const persistor = persistStore(store);

store.persistor = persistor;

export { store, persistor };
