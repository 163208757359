import { POST_OUR_WORK_ERROR, POST_OUR_WORK_START, POST_OUR_WORK_SUCCESS } from '../../types';

export default function post_our_work(state, action) {
  switch (action.type) {
    case POST_OUR_WORK_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case POST_OUR_WORK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case POST_OUR_WORK_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}