import { POST_HOW_TO_APPLY_ERROR, POST_HOW_TO_APPLY_START, POST_HOW_TO_APPLY_SUCCESS } from '../../types';

export default function post_how_to_apply(state, action) {
  switch (action.type) {
    case POST_HOW_TO_APPLY_START:
      return {
        ...state,
        loading: true,
        error: null
      };
    case POST_HOW_TO_APPLY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case POST_HOW_TO_APPLY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.detail
      };
    default:
      return { ...state };
  }
}