import { PUT_PORTOFOLIO_ERROR, PUT_PORTOFOLIO_START, PUT_PORTOFOLIO_SUCCESS } from '../../types';

export default function put_portofolio(state, action) {
  switch (action.type) {
    case PUT_PORTOFOLIO_START:
      return { ...state };
    case PUT_PORTOFOLIO_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case PUT_PORTOFOLIO_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}