/** @jsxImportSource @emotion/react */
import React from 'react'
import { useDispatch } from 'react-redux'

import { FormLogin } from '../../../components/templates'
import { background, sideSection } from './index.styles'
import { login } from '../../../redux/actions/auth/login';
import backgroundPng from "../../../assets/images/bg.svg";

const Login: React.FC = () => {
  const dispatch = useDispatch()
  const handleLogin = (email: string, password: string) => {
    console.log(email, password)
    dispatch(login({ email: email, password, keepAlive: true }))
  }
  return (
    <div css={background} style={{ backgroundImage: `url(${backgroundPng})`}}>
      <div css={sideSection}>
        <FormLogin onLogin={handleLogin} />
        <div
          style={{
            color: 'hsl(0, 0%, 40%)',
            display: 'inline-block',
            fontSize: 12,
            fontStyle: 'italic',
            marginBottom: '1em'
          }}
        >
        </div>
      </div>
    </div>
  )
}

export default Login
