/** @jsxImportSource @emotion/react **/
import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";

import { LANG_ALL, styleContainer } from '../../../pages/utils';
import { Context } from '../../../context/ListItemsContext'
import { BaseInput, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from "../Header/index.styles";


const AboutUsForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.get_list_items.data)
  const about = useSelector((state: any) => state.get_about_us.data)
  const our_work = useSelector((state: any) => state.get_our_work.data)
  const apply = useSelector((state: any) => state.get_card_apply.data)

  const news_data = id ? detail_data?.data.find((r: any) => r.id === id) : null
  const about_data = id ? about?.data.find((r: any) => r.id === id) : null
  const our_data = id ? our_work?.data.find((r: any) => r.id === id) : null
  const apply_data = id ? apply?.data.find((r: any) => r.id === id) : null

  useEffect(() => {
    if (!!id) {
      handleData(news_data)
    }
    console.log(about)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])

  const [aboutOptions, setAboutOptions] = useState<any>([])
  const aboutInit = about_data?.title ? { value: about_data.title, label: about_data.title } : { value: '', label: '' }
  type aboutExtract = {
    id: string,
    title: string,
    lang: string,
  }
  useEffect(() => {
    const aboutUsConstructed = about?.data?.map(({ title, lang, id }: aboutExtract) =>
      ({ label: `${title} - ${lang}`, value: id })
    )
    setAboutOptions(aboutUsConstructed)
  }, [about])

  const [ourOptions, setOurOptions] = useState<any>([])
  const ourInit = our_data?.title ? { value: our_data.title, label: our_data.title } : { value: '', label: '' }
  type ourExtract = {
    id: string,
    title: string,
    lang: string,
  }
  useEffect(() => {
    const ourConstructed = our_work?.data?.map(({ title, lang, id }: ourExtract) =>
      ({ label: `${title} - ${lang}`, value: id })
    )
    setOurOptions(ourConstructed)
  }, [our_work])

  const [applyOptions, setApplyOptions] = useState<any>([])
  const applyInit = apply_data?.title ? { value: apply_data.title, label: apply_data.title } : { value: '', label: '' }
  type applyExtract = {
    id: string,
    title: string,
    lang: string,
  }
  useEffect(() => {
    const applyConstructed = apply?.data?.map(({ title, lang, id }: applyExtract) =>
      ({ label: `${title} - ${lang}`, value: id })
    )
    setApplyOptions(applyConstructed)
  }, [apply])

  const langInit = news_data?.lang ? { value: news_data.lang, label: news_data.lang } : { value: '', label: '' }
  const [isTitleValid, setTitleValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])

  const handleLang = (e: any) => {
    handleChange({ lang: e.value })
  }

  const handleAbout = (e: any) => {
    handleChange({ about: e.value })
  }

  const handleOur = (e: any) => {
    handleChange({ our: e.value })
  }

  const handleApply = (e: any) => {
    handleChange({ apply: e.value })
  }

  return (
    <ContentSection
      style={styleContainer}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} Item</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Titulo'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe un titulo'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <Text textType="label">
        ¿Quiénes somos? :{' '}
        <Select css={styles.input}
          options={aboutOptions}
          defaultValue={aboutInit}
          onChange={(e) => handleAbout(e)}
        />
      </Text>
      <Text textType="label">
        ¿Qué hacemos? :{' '}
        <Select css={styles.input}
          options={ourOptions}
          defaultValue={ourInit}
          onChange={(e) => handleOur(e)}
        />
      </Text>

      <Text textType="label">
        ¿Como aplicar? :{' '}
        <Select css={styles.input}
          options={applyOptions}
          defaultValue={applyInit}
          onChange={(e) => handleApply(e)}
        />
      </Text>
      <Text textType="label">
        IDIOMA :{' '}
        <Select css={styles.input}
          options={LANG_ALL}
          defaultValue={langInit}
          onChange={(e) => handleLang(e)}
        />
      </Text>
      {/* <SwitchInput
        checked={formData.isActive}
        onCheckedChange={(value) => handleChange({ isActive: value })}
        label={formData.isActive ? 'Activo' : 'Inactivo'}
      /> */}

    </ContentSection>
  )
}

export default AboutUsForm
