import { PUT_INFO_CARDS_ERROR, PUT_INFO_CARDS_START, PUT_INFO_CARDS_SUCCESS } from '../../types';

export default function put_info_cards(state, action) {
  switch (action.type) {
    case PUT_INFO_CARDS_START:
      return { ...state };
    case PUT_INFO_CARDS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case PUT_INFO_CARDS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}