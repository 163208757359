/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { useLocation } from "react-router-dom";
import { Breadcrumb } from '../../../components/atoms'
import { breadcrumbItems } from '../../utils'

const { index, contentManagement, infoTalentManagement } = breadcrumbItems

const ManageInfoTalent: React.FC = () => {
  const navigate = useNavigate()
  let location = useLocation();
  const data = useSelector((state: any) => state.get_info_talent.data)

  useEffect(() => {
    if (location.pathname === '/app/content-management/manage-info-cards') {
      setTimeout(() => {
        // dispatch(banners({ token }));
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, infoTalentManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar Bloque de texto"
            subtitle="Bloque de texto - publicados"
            columns={columns}
            data={data ? data?.data : []}
            route={'/app/content-management/create-info-talent/'}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo - Bloque de texto"
            message=""
            textButton="Crear nuevo"
            onCreate={() => navigate('/app/content-management/create-info-talent')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageInfoTalent;
