import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_FOUNDERS_ERROR, GET_FOUNDERS_START, GET_FOUNDERS_SUCCESS } from '../../types';

import apiCall from '../../../api/apiGet';

export function* get({ payload }) {
  try {
    const url = payload.lang ? `/founders/${payload.lang}` : '/founders';
    const method = 'GET';
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, headers);
    console.log(results)
    yield put({ type: GET_FOUNDERS_SUCCESS, results: results.data });

  } catch (error) {
    console.log(error)
    yield put({ type: GET_FOUNDERS_ERROR, error });
  }
}

export default function* get_founders() {
  yield takeLatest(GET_FOUNDERS_START, get);
}
