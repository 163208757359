/** @jsxImportSource @emotion/react */
import React, {Fragment, useMemo, useState} from 'react'
import {
  Column,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table'
import {useNavigate} from "react-router-dom";

import {Text} from '../../atoms'
import * as styles from './index.styles'
import { Modal } from '../../../components/templates'
import { toast } from 'react-toastify';
import { Button } from '../../atoms/Button/index.stories'

export type Props = {
  columns: Column<any>[]
  data: any[],
  route?: string,
}

const TableBase: React.FC<Props> = ({columns: columnsTable, data: dataTable, route}) => {
  const navigate = useNavigate();
  const columns = useMemo((): Column<any>[] => columnsTable, [columnsTable])
  const data = useMemo((): any[] => dataTable, [dataTable])
  const tabla = useTable({ columns, data }, useGlobalFilter, useSortBy)
  const [show, setShow] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [idData, setIdData] = useState({id: 0, type: ''})

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tabla

  const handleDirect = (row: any) => {
    navigate(`${route}${row.original.id}`)
  }

  const actionDelete = (data: any) => {
    let id = data.id
    console.log(id)
/*
    switch(data.type) {
      case 'News':
        dispatch(delete_news({ token, id }))
        setTimeout(() => {
          dispatch(group_news({ token, id }))
          navigate('/app/content-management/manage-group-news')
        }, 1500)
        break;
      default:
        // code block
      }
*/
    toast.success('Eliminación exitosa!', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
    //navigate(`/app/dashboard`)
    setShow(false)
  }

  return (
    <Fragment>
      <Modal
          show={show}
          text=""
          title="¿Esta seguro que desea eliminar?"
          btnSuccess="Cancelar"
          btnWarning="Eliminar"
          onClickWarning={() => {actionDelete(idData)}}
          onClickSuccess={() => {setShow(false)}}
          onClickClose={() => {setShow(false)}}
      />

      <table {...getTableProps()} css={styles.table}>
        <thead css={styles.tableHeader}>
          {headerGroups.map((headerGroup, index) => (
            <tr
              {...headerGroup.getHeaderGroupProps()}
              css={styles.tableHeaderRow}
              key={`tr-${index}`}
            >
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  css={styles.tableHeaderCell}
                  key={`th-${column.render('Header')}`}
                >
                  {column.render('Header')}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <span> &darr;</span>
                    ) : (
                      <span> &uarr;</span>
                    )
                  ) : (
                    ''
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} css={styles.tableBody}>
          {rows.map((row) => {
            prepareRow(row)
            return (
                <tr {...row.getRowProps()} css={styles.tableBodyRow}>
                  {row.cells.map((cell) => {
                    if(cell.column.id === 'isActive') {
                      return (
                        <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                          {cell.render('Cell') ? "Activo" : "Inactivo"}
                        </td>
                      )
                    } if (cell.column.id === 'actions') {
                      return (
                        <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                          <Button onClick={() => handleDirect(row)}>Ver</Button>
                        </td>
                      );
                    }
                    else {
                      return (
                        <td {...cell.getCellProps()} css={styles.tableBodyCell}>
                        {cell.render('Cell')}
                        </td>
                      )
                    }
                  })}
                </tr>
            )
          })}
        </tbody>
        <Text textType="sm">{rows.length===0?'No hay datos en la tabla.':''}</Text>
      </table>
    </Fragment>
  )
}

export default TableBase
