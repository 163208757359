/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, BaseInput, Text } from '../../atoms'
import * as styles from './index.styles'
import {useTranslation} from "react-i18next";

type Props = { onLogin: (email: string, password: string) => void }

const FormLogin: React.FC<Props> = ({ onLogin }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [isUsernameValid, setIsUsernameValid] = useState<boolean>(false)
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [rememberMe, setRememberMe] = useState<boolean>(false)
  const [isFirstRender, setIsFirstRender] = useState<boolean>(true)

  useEffect(() => {
    setIsPasswordValid(password.length > 4)
  }, [password])
  useEffect(() => {
    if (email.length > 3) {
      setIsUsernameValid(true)
    } else {
      setIsUsernameValid(false)
    }
  }, [email])
  useEffect(() => {
    isFirstRender && setIsFirstRender(false)
  }, [isFirstRender])
  return (
    <div css={styles.container}>
      <img
        src="/logo.svg"
        alt="logo"
        css={{ marginBottom: '46px', width: 'inherit' }}
      />
      <div css={{ marginBottom: 59 }}>
        <Text textType="h3" weight="black">
          {t('auth:title')}
        </Text>
      </div>
      <BaseInput
        placeholder={t('auth:text_input_username')}
        name="email"
        value={email}
        onChange={(value) => setEmail(value)}
        success={isUsernameValid}
        style={css({ marginBottom: 40 })}
      />
      <BaseInput
        placeholder={t('auth:text_input_password')}
        name="password"
        type="password"
        value={password}
        onChange={(value) => setPassword(value)}
        error={!isPasswordValid && password.length > 0}
        success={isPasswordValid}
        style={css({ marginBottom: 29 })}
      />

      <div css={{ marginBottom: 36, alignSelf: 'start' }}>
        <Checkbox checked={rememberMe} onChange={setRememberMe}>
          {t('auth:text_check_remember')}
        </Checkbox>
      </div>

      <div css={{ marginBottom: 25,width: '100%' }}>
        <Button
          disabled={!isUsernameValid || !isPasswordValid}
          onClick={() => onLogin(email, password)}
        >
          {t('auth:title')}
        </Button>
      </div>
       {/* <div css={{ marginBottom: 91, color: '#34189A'}}>
            <Text textType='sm'>¿Olvidaste tu contraseña?</Text>
        </div>*/}
    </div>
  )
}

export default FormLogin
