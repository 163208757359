import { css } from "@emotion/react";

export const root = css`
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  display: flex;
  align-items: center;
`

export const iconsContainer = css`
  display: flex;
  align-items: center;
  & svg {
    margin-right: 10px;
  }
`

export const avatarContainer = css`
  display: flex;
  align-items: center;
  & img {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    border-radius: 50%;
    transition: all 500ms;
  }
  & > div > div {
    white-space: nowrap;
    &::nth-of-type(2) {
      margin: 0;
      margin-top: 3px;
      text-transform: upperCase;
      font-size: 7px;
      line-height: 8px;
      color: #FC2E08;
    }
  }
`

export const actions = css`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;

`

export const marginB = css`
  margin-bottom: 15px;
`

export const openYes = css`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #EDEAF6;
  padding: 1rem;
  z-index: 99;
  display: flex;
  flex-direction: column;
  justify-content: start;
  //align-items: center;
`

export const openNo = css`
  display: none;
`
export const iconClose = css`
  position: absolute;
  right: 1rem;
  cursor: pointer;
`

export const iconMenu = css`
  display: none;
  cursor: pointer;
  @media (max-width: 1264px){
    display: block;
  }
`
