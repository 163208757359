/** @jsxImportSource @emotion/react */
import React from 'react'
import * as styles from './index.styles'
import BrainIcon from './BrainIcon'

const Footer: React.FC = () => {

  return (
    <div css={styles.root}>
      {/* <Text textType='p' >{new Date().getFullYear()} © <div style={{display: 'inline-block',cursor: 'pointer', margin: '0'}} onClick={goToUrl}>Soy Digital.</div> {t('footer:text_made')} </Text> */}
      <BrainIcon />
    </div>
  )
}

export default Footer
