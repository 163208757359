/** @jsxImportSource @emotion/react */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { CloseIcon, MenuIcon } from '../../../assets'
//import { QuestionIcon } from '../../../assets'
import { Text } from '../../atoms'
import * as styles from './index.styles'
import { login } from "../../../redux/actions/auth/login";
import { useNavigate } from "react-router-dom";
import { Modal } from '../../../components/templates'
import navListRoutes from "../../../router/navListRoutes";
import { NavSection } from "../index";
import Select from "react-select";
import { options_i18 } from "../../../options_i18";
import { language_manager } from "../../../language_manager";
import { setLanguage } from "../../../redux/actions/language";
import { useTranslation } from "react-i18next";
import userAvatar from "../../../assets/images/favicon.png";


const Notifications: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const handleLogout = () => {
    dispatch(login('LOGOUT'))
    navigate('/login');
    setShow(false)
  }

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };
  const goToHome = () => {
    navigate('/');
  }

  const handleLanguage = (e: string) => {
    language_manager(e)
    dispatch(setLanguage(e))
  }

  return (
    <div css={styles.root}>
      <Modal
        show={show}
        title={t('auth:text_logout')}
        text=""
        btnSuccess={t('common:text_yes')}
        btnWarning={t('common:text_no')}
        onClickSuccess={() => handleLogout()}
        onClickWarning={() => { setShow(false) }}
        onClickClose={() => { setShow(false) }}
      />

      <div
        style={{
          color: 'hsl(0, 0%, 40%)',
          display: 'inline-block',
          fontSize: 12,
          fontStyle: 'italic',
          marginLeft: '5px',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <Select
          className="basic-single"
          classNamePrefix="select"
          defaultValue={options_i18[0]}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          name="color"
          options={options_i18}
          onChange={(e: any) => handleLanguage(e.value)}
        />

      </div>
      <div css={styles.iconsContainer}>
        {/* <QuestionIcon width={24} height={24} /> */}
        <MenuIcon width={24} height={24} css={styles.iconMenu}
          onClick={handleClick} />
      </div>
      <div css={styles.avatarContainer}>
        <img src={userAvatar} alt="user" css={{ cursor: 'pointer' }} onClick={() => { setShow(true) }} />
        <div >
          <Text textType="p">ESCALATEC</Text>
          <Text textType="label">Administrador</Text>
        </div>
      </div>
      <div css={open ? styles.openYes : styles.openNo}>
        <CloseIcon width={24} height={24} css={styles.iconClose}
          onClick={handleClick} />
        <img src='/logo.svg' alt="logo" width={167} css={{ marginTop: '2rem', marginBottom: '46px', cursor: 'pointer' }} onClick={goToHome} />
        <aside>
          {
            navListRoutes.map(routeSection => (
              <NavSection key={`sidebarSection-${routeSection.title}`} routeSection={routeSection} />
            ))
          }
        </aside>
      </div>

    </div>
  )
}

export default Notifications
