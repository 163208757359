/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Column } from "react-table";
import { Text, BaseInput } from "../../atoms";
import { TableBase } from "../../molecules";
import { ContentSection } from "../../organisms";
import * as styles from "../../molecules/TableBase/index.styles";
import { styleBaseInput, styleContentSection } from "../../../pages/utils";

type Props = {
  title: string;
  subtitle: string;
  onClickApply?: () => void;
  onSearch?: (search: string) => void;
  columns: Column<any>[];
  data: any[];
  handleChange?: any;
  route?: string;
};

const ManageTableUsers: React.FC<Props> = ({
  title,
  subtitle,
  onClickApply = () => {},
  onSearch = () => {},
  columns,
  data,
  handleChange,
  route,
}) => {
  const [filter, setFilter] = useState<string>("");

  const [filters, setFilters] = useState<{ key: string; value: string }[]>([]);

  const filterHandler = (key: any, value: string) => {
    if (key !== null) {
      const index = filters.findIndex((f: any) => f.key === key);
      if (index === -1) {
        setFilters((prev: any) => {
          prev.push({ key, value });
          return prev;
        });
      } else {
        setFilters((prev) => {
          prev[index] = { key, value };
          return prev;
        });
      }

      if (value === "Ninguno") {
        setFilters((prev) => {
          prev.splice(index, 1);
          return prev;
        });
      }
    }

    let aux = data;
    filters.forEach((filter: any) => {
      aux = aux.filter((row) => {
        if (row[filter.key].length > 0) {
          return row[filter.key].indexOf(filter.value) > -1;
        } else {
          return row[filter.key] === filter.value;
        }
      });
    });

    if (key === null) {
      aux = globalFilter(aux, value);
    }
  };

  const globalFilter = (rows: any[], text: string): any[] => {
    return rows.filter((row) => {
      let sw = false;
      Object.values(row).forEach((v: any) =>
        (v + "").toLowerCase().indexOf(text.toLowerCase()) > -1
          ? (sw = true)
          : null
      );
      return sw;
    });
  };

  return (
    <ContentSection
      style={styleContentSection(title)}
      header={
        <React.Fragment>
          <Text textType="h4" weight="black">
            {title}
          </Text>
          <Text textType="p" weight="bold">
            {subtitle}
          </Text>
        </React.Fragment>
      }
    >
      <div css={styles.actions}>
        <BaseInput
          style={styleBaseInput(title)}
          name="search"
          value={filter}
          placeholder="Buscar"
          onChange={(event: any) => {
            setFilter(event);
            filterHandler(null, event);
          }}
        />
      </div>

      <TableBase columns={columns} data={data} route={route} />
    </ContentSection>
  );
};

export default ManageTableUsers;
