import { POST_INFO_CARDS_ERROR, POST_INFO_CARDS_START, POST_INFO_CARDS_SUCCESS } from '../../types';

export default function post_info_cards(state, action) {
  switch (action.type) {
    case POST_INFO_CARDS_START:
      return { ...state };
    case POST_INFO_CARDS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case POST_INFO_CARDS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}