import { POST_INFO_TALENT_ERROR, POST_INFO_TALENT_START, POST_INFO_TALENT_SUCCESS } from '../../types';

export default function post_info_talent(state, action) {
  switch (action.type) {
    case POST_INFO_TALENT_START:
      return { ...state };
    case POST_INFO_TALENT_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case POST_INFO_TALENT_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}