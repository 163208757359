import { POST_PORTOFOLIO_ERROR, POST_PORTOFOLIO_START, POST_PORTOFOLIO_SUCCESS } from '../../types';

export default function post_portofolio(state, action) {
  switch (action.type) {
    case POST_PORTOFOLIO_START:
      return { ...state };
    case POST_PORTOFOLIO_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case POST_PORTOFOLIO_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}