/** @jsxImportSource @emotion/react */
import React from 'react'
import { InfoTalentForm, InfoTalentPreview } from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/InfoTalentContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, infoTalentManagement } = breadcrumbItems

const InfoTalentCreate: React.FC = () => {
  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, infoTalentManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <InfoTalentForm />
        </div>
        <div css={styles.right}>
          <InfoTalentPreview />
        </div>
      </div>
    </Provider>
  )
}

export default InfoTalentCreate;
