/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from "../../../context/AboutUsContext";
import { post_about_us } from '../../../redux/actions/about_us/post_about_us';
import { put_about_us } from '../../../redux/actions/about_us/put_about_us';
import { delete_about_us } from '../../../redux/actions/about_us/delete_about_us';
//import { get_about_us } from '../../../redux/actions/about_us/get_about_us';
import Modal from '../Modal/index';


const AboutUsPreview: React.FC = () => {
  const dispatch = useDispatch()
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const { formData } = useContext(Context)
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)

  const handleChange = (token: any, formData: any) => {
    if (id) {
      console.log(id)
      dispatch(put_about_us({ id, token, data: formData, navigate }))
    } else {
      delete formData.id
      dispatch(post_about_us({ token, data: formData, navigate }))
    }
  }

  const handleDelete = () => {
    dispatch(delete_about_us({ id, token, data: formData, navigate }))
    //dispatch(get_about_us({ lang: null, token }))
  }

  const successAction = () => {
    handleChange(token, formData)
    //dispatch(get_about_us({ lang: null, token }))
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModalDelete = () => {
    setShowModalDelete(false)
  }


  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.title}
          </Text>
          <div css={styles.previewDescription(theme)}>
            <Text textType='sm' weight='light'>
              {formData.description}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.analyzed}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.invested}
            </Text>
            <Text textType='sm' weight='light'>
              {formData.lang}
            </Text>
          </div>
        </Card>
      </div>
      <Button onClick={() => {
        setShowModal(true)
      }} disabled={!formData.title || !formData.lang
        || !formData.description}>
        {!id ? 'Publicar' : 'Guardar'} ¿Quienes Somos?
      </Button>
      {
        id ?
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de modificar esta pagina?'}
              text={' '}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal} />
          ) :
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de publicar esta pagina ?'}
              text={''}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal}
            />
          )
      }

      {
        id && (
          <>
            <Button onClick={() => {
              setShowModalDelete(true)
            }}>
              Eliminar pagina
            </Button>
            <Modal
              show={showModalDelete}
              title={'¿Esta seguro de eliminar esta pagina?'}
              text={' '}
              onClickSuccess={handleDelete}
              onClickClose={closeModalDelete}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModalDelete}
            />
          </>
        )}
    </ContentSection>
  )
}


export default AboutUsPreview;
