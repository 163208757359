import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_CARD_APPLY_ERROR, GET_CARD_APPLY_START, GET_CARD_APPLY_SUCCESS } from '../../types';

import apiCall from '../../../api/apiGet';

export function* get({ payload }) {
  try {
    const url = payload.lang ? `/card-apply/${payload.lang}` : '/card-apply';
    const method = 'GET';
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, headers);
    console.log(results)
    yield put({ type: GET_CARD_APPLY_SUCCESS, results: results.data });

  } catch (error) {
    console.log(error)
    yield put({ type: GET_CARD_APPLY_ERROR, error });
  }
}

export default function* get_card_apply() {
  yield takeLatest(GET_CARD_APPLY_START, get);
}
