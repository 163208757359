/** @jsxImportSource @emotion/react **/
import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";

import { LANG_ALL, styleContainer } from '../../../pages/utils';
import { Context } from '../../../context/StaffTeamContext'
import { BaseInput, FileInput, Text, TextareaInput } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from "../Header/index.styles";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../firebaseConfig";


const AboutUsForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.get_staff_cards.data)
  const news_data = id ? detail_data?.data.find((r: any) => r.id === id) : null
  const staff = useSelector((state: any) => state.get_staff.data)

  useEffect(() => {
    if (!!id) {
      handleData(news_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])

  const langInit = news_data?.lang ? { value: news_data.lang, label: news_data.lang } : { value: '', label: '' }
  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isHrefValid, setHrefValid] = useState<boolean>(false)
  const [file, setFile] = useState<any>();
  const [image, setImage] = useState<string>();
  const [progress, setProgress] = useState<any>(null);

  useEffect(() => {
    setTitleValid(formData.name.length > 2)
  }, [formData.name])

  useEffect(() => {
    setHrefValid(formData.job_position.length > 0)
  }, [formData.job_position])

  const handleLang = (e: any) => {
    handleChange({ lang: e.value })
  }

  const handleStaff = (e: any) => {
    handleChange({ staff: e.value })
  }

  const handleChangeFile = (event: any) => {
    setFile(event)
  }

  useEffect(() => {
    if (file) {
      handleUpload()
    }
    // eslint-disable-next-line
  }, [file])

  const handleUpload = () => {
    if (!file) {
      alert("Debe seleccionar una imagen");
    }
    // @ts-ignore: Object is possibly 'null'.
    const storageRef = ref(storage, `/files/${file!.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file!);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percent)
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          // handleChange({ image: url })
          setImage(url)
          setFile(undefined);
        });
      }
    );
  };

  useEffect(() => {
    if (image) {
      handleChange({ image: image })
    }
    // eslint-disable-next-line
  }, [image])

  const [staffOptions, setStaffOptions] = useState<any>([])
  const staffInit = { value: '', label: '' }
  type staffExtract = {
    id: string,
    title: string,
    lang: string,
  }
  useEffect(() => {
    const staffConstructed = staff.data?.map(({ title, lang, id }: staffExtract) =>
      ({ label: `${title} - ${lang}`, value: id })
    )
    setStaffOptions(staffConstructed)
  }, [staff])


  return (
    <ContentSection
      style={styleContainer}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} Integrante del Staff</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Nombres'
        value={formData.name}
        onChange={(value) => handleChange({ name: value })}
        placeholder='Nombre completo'
        maxLength={500}
        error={!isTitleValid && formData.name.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.name.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Posición'
        value={formData.job_position}
        onChange={(value) => handleChange({ job_position: value })}
        placeholder='Escribe la posición'
        maxLength={500}
        error={!isHrefValid && formData.job_position.length > 0}
        success={isHrefValid}
        hint={!isHrefValid && formData.job_position.length > 0 ? 'Digite mas de una letra' : ''}
      />
      <BaseInput
        label='Linkedin'
        value={formData.linkedin}
        onChange={(value) => handleChange({ linkedin: value })}
        placeholder='Linkedin'
        maxLength={500}
        error={!isTitleValid && formData.linkedin.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.linkedin.length > 0 ? 'Digite mas de dos letras' : ''}
      />

      <div style={{ width: 360 }}>
        <FileInput
          label="Imagen( formato:.PNG/.JPG tamaño: 635x635px )"
          value={file}
          onChange={handleChangeFile}
          accept="image/*"
        />
        <Text textType='h4' weight='black'>{progress === 100 && 'Imagen subida'}</Text>
      </div>
      <Text textType="label">
        Equipo :{' '}
        <Select css={styles.input}
          options={staffOptions}
          defaultValue={staffInit}
          onChange={(e) => handleStaff(e)}
        />
      </Text>
      <Text textType="label">
        IDIOMA :{' '}
        <Select css={styles.input}
          options={LANG_ALL}
          defaultValue={langInit}
          onChange={(e) => handleLang(e)}
        />
      </Text>
      {/* <SwitchInput
        checked={formData.isActive}
        onCheckedChange={(value) => handleChange({ isActive: value })}
        label={formData.isActive ? 'Activo' : 'Inactivo'}
      /> */}

    </ContentSection>
  )
}

export default AboutUsForm
