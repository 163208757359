import { GET_INFO_TALENT_ERROR, GET_INFO_TALENT_START, GET_INFO_TALENT_SUCCESS } from '../../types';

export default function get_info_talent(state, action) {
  switch (action.type) {
    case GET_INFO_TALENT_START:
      return { ...state };
    case GET_INFO_TALENT_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case GET_INFO_TALENT_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}
