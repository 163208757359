import { PUT_BANNERS_ERROR, PUT_BANNERS_START, PUT_BANNERS_SUCCESS } from '../../types';

export default function put_banners(state, action) {
  switch (action.type) {
    case PUT_BANNERS_START:
      return { ...state };
    case PUT_BANNERS_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case PUT_BANNERS_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}
