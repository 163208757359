/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { useLocation } from "react-router-dom";
import { Breadcrumb } from '../../../components/atoms'
import { breadcrumbItems } from '../../utils'
import moment from 'moment'
import { login } from '../../../redux/actions/auth/login'
import jwtDecode from 'jwt-decode'

interface MyToken {
  exp: number
}

const { index, contentManagement, bannersManagement } = breadcrumbItems

const ManageBanners: React.FC = () => {
  const navigate = useNavigate()
  let location = useLocation();
  const dispatch = useDispatch()
  const data = useSelector((state: any) => state.get_banners.data)
  const [info, setInfo] = useState<any>([])
  // const loading = useSelector((state: any) => state.post_banners)
  // const token = useSelector((state: any) => state.login.access)
  const token = useSelector((state: any) => state.login.access)

  useEffect(() => {
    const decoded = jwtDecode<MyToken>(token)
    console.log(decoded)
    const now = moment().unix()
    const duration = moment.duration(
      moment.unix(now).diff(moment.unix(decoded.exp))
    )
    const getMinutes = duration.asMinutes()
    if (getMinutes > 1) {
      dispatch(login('LOGOUT'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  // useEffect(() => {
  //   // if (loading) {
  //   //   if (!loading.loading) {
  //   //     setTimeout(() => {
  //   //       console.log(true)
  //   //       dispatch(get_banners({ lang: null, token }))
  //   //     }, 1000)
  //   //   }
  //   // }
  // }, [loading])
  useEffect(() => {
    if (data) {
      setInfo(data.data)
    }
  }, [data])

  useEffect(() => {
    if (location.pathname === '/app/content-management/manage-banners') {

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  console.log(data)

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, bannersManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar - Banners"
            subtitle="Banners - publicados"
            columns={columns}
            data={info}
            route={'/app/content-management/create-banners/'}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo - Banner"
            message=""
            textButton="Crear nuevo"
            onCreate={() => navigate('/app/content-management/create-banners')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageBanners;
