/** @jsxImportSource @emotion/react */
import React, { Fragment } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
//import { useLocation } from "react-router-dom";
import { Breadcrumb } from '../../../components/atoms'
import { breadcrumbItems } from '../../utils'

const { index, contentManagement, enterprisesManagement } = breadcrumbItems

const ManageEnterprises: React.FC = () => {
  const navigate = useNavigate()
  //let location = useLocation();
  const data = useSelector((state: any) => state.get_enterprises.data)

  // useEffect(() => {
  //   if (location.pathname === '/app/content-management/manage-enterprises') {
  //     setTimeout(() => {
  //       // dispatch(banners({ token }));
  //     }, 1500)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, enterprisesManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar - Empresas"
            subtitle="Empresas - publicados"
            columns={columns}
            data={data ? data?.data : []}
            route={'/app/content-management/create-enterprises/'}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo - Empresas"
            message=""
            textButton="Crear nuevo"
            onCreate={() => navigate('/app/content-management/create-enterprises')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManageEnterprises;
