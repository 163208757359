import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
const app = initializeApp ({
    apiKey: "AIzaSyCyyAb0ZSDtxnY2Su1u4lRKnrgKV1RJvz8",
    authDomain: "web-escalatec.firebaseapp.com",
    projectId: "web-escalatec",
    storageBucket: "web-escalatec.appspot.com",
    messagingSenderId: "1026817081825",
    appId: "1:1026817081825:web:26e6de633e4006434b12a0",
    measurementId: "G-87PKHX5XYE"
});
 
const storage = getStorage(app);
export default storage;
