/** @jsxImportSource @emotion/react **/
import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../firebaseConfig";

import { LANG_ALL, styleContainer } from '../../../pages/utils';
import { Context } from '../../../context/OurWorkContext'
import { BaseInput, FileInput, Text, TextareaInput } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from "../Header/index.styles";


const AboutUsForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.get_our_work.data)
  const news_data = id ? detail_data?.data.find((r: any) => r.id === id) : null
  const [file, setFile] = useState<any>();
  const [progress, setProgress] = useState<any>(null);
  const [image, setImage] = useState<string>()

  useEffect(() => {
    if (!!id) {
      handleData(news_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])

  const langInit = news_data?.lang ? { value: news_data.lang, label: news_data.lang } : { value: '', label: '' }
  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isDescriptionValid, setDescriptionValid] = useState<boolean>(false)
  //const [isImageValid, setIsImageValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])

  useEffect(() => {
    setDescriptionValid(formData.description.length > 0)
  }, [formData.description])

  // useEffect(() => {
  //   const temp = validURL(formData.image)
  //   setIsImageValid(temp)
  // }, [formData.image])

  const handleLang = (e: any) => {
    handleChange({ lang: e.value })
  }

  const handleChangeFile = (event: any) => {
    setFile(event)
  }

  useEffect(() => {
    if (file) {
      handleUpload()
    }
    // eslint-disable-next-line
  }, [file])

  const handleUpload = () => {
    if (!file) {
      alert("Debe seleccionar una imagen");
    }
    // @ts-ignore: Object is possibly 'null'.
    const storageRef = ref(storage, `/files/${file!.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file!);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percent)
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          //handleChange({ image: url })
          setImage(url)
          setFile(undefined);
        });
      }
    );
  };

  useEffect(() => {
    if (image) {
      handleChange({ image: image })
    }
    // eslint-disable-next-line
  }, [image])

  return (
    <ContentSection
      style={styleContainer}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} ¿Que hacemos?</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Titulo'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe un titulo'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <TextareaInput
        label='Descripción'
        value={formData.description}
        onChange={(value) => handleChange({ description: value })}
        placeholder='Escribe una descripción'
        maxLength={500}
        error={!isDescriptionValid && formData.description.length > 0}
        success={isDescriptionValid}
        hint={!isDescriptionValid && formData.description.length > 0 ? 'Digite mas de una letra' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label="Imagen( formato:.PNG/.JPG tamaño: 635x635px )"
          value={file}
          onChange={handleChangeFile}
          accept="image/*"
        />
        <Text textType='h4' weight='black'>{progress === 100 && 'Imagen subida'}</Text>
      </div>
      <Text textType="label">
        IDIOMA :{' '}
        <Select css={styles.input}
          options={LANG_ALL}
          defaultValue={langInit}
          onChange={(e) => handleLang(e)}
        />
      </Text>
      {/* <SwitchInput
        checked={formData.isActive}
        onCheckedChange={(value) => handleChange({ isActive: value })}
        label={formData.isActive ? 'Activo' : 'Inactivo'}
      /> */}

    </ContentSection>
  )
}

export default AboutUsForm
