
import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_CARD_APPLY_START, POST_CARD_APPLY_ERROR, POST_CARD_APPLY_START, POST_CARD_APPLY_SUCCESS } from '../../types';

import apiCall from '../../../api';

export function* create({ payload }) {
  try {
    const url = '/card-apply/create';
    const method = 'POST';
    const data = payload.data;
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, data, headers);
    console.log(results)
    yield put({ type: POST_CARD_APPLY_SUCCESS, results: results.data });
  } catch (error) {
    console.log(error)
    yield put({ type: POST_CARD_APPLY_ERROR, error });
  } finally {
    yield put({ type: GET_CARD_APPLY_START, payload: {lang: null, token: payload.token} });
    payload.navigate(-1);
  }
}

export default function* get_card_apply() {
  yield takeLatest(POST_CARD_APPLY_START, create);
}