/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { Breadcrumb } from '../../../components/atoms'
import { Traffic } from '../../../components/templates'
import * as styles from '../index.styles'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from "react-i18next";
import { get_about_us } from "../../../redux/actions/about_us/get_about_us";
import i18n from "i18next";
import { setLanguage } from "../../../redux/actions/language";
import { get_banners } from '../../../redux/actions/banners/get_banners'
import { get_faqs } from '../../../redux/actions/faqs/get_faqs'
import { get_info_cards } from '../../../redux/actions/info_cards/get_info_cards'
import { get_list_items } from '../../../redux/actions/list_items/get_list_items'
import { get_our_work } from '../../../redux/actions/our_work/get_our_work'
import { get_portofolio } from '../../../redux/actions/portofolio/get_portofolio'
import { get_staff_cards } from '../../../redux/actions/staff_cards/get_staff_cards'
import { get_staff } from '../../../redux/actions/staff/get_staff'
import { get_enterprises } from '../../../redux/actions/enterprises/get_enterprises'
import { get_founders } from '../../../redux/actions/founders/get_founders'
import { get_how_to_apply } from '../../../redux/actions/how_to_apply/get_how_to_apply'
import { useNavigate } from 'react-router'
import { get_info_apply } from '../../../redux/actions/info_apply/get_info_apply'
import { get_info_talent } from '../../../redux/actions/info_talent/get_info_talent'
import { get_card_apply } from '../../../redux/actions/card_apply/get_card_apply'

const Dashboard: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation();
  const dispatch = useDispatch()
  const token = useSelector((state: any) => state.login.access)
  const lang = useSelector((state: any) => state.language.language)

  useEffect(() => {
    if (!lang) {
      i18n.changeLanguage('es');
      dispatch(setLanguage('es'))
    }
    dispatch(get_about_us({ lang: null, token }))
    dispatch(get_banners({ lang: null, token }))
    dispatch(get_faqs({ lang: null, token }))
    dispatch(get_info_cards({ lang: null, token }))
    dispatch(get_list_items({ lang: null, token }))
    dispatch(get_our_work({ lang: null, token }))
    dispatch(get_portofolio({ lang: null, token }))
    dispatch(get_staff_cards({ lang: null, token }))
    dispatch(get_staff({ lang: null, token }))
    dispatch(get_enterprises({ lang: null, token }))
    dispatch(get_founders({ lang: null, token }))
    dispatch(get_how_to_apply({ lang: null, token }))
    dispatch(get_info_apply({ lang: null, token }))
    dispatch(get_info_talent({ lang: null, token }))
    dispatch(get_card_apply({ lang: null, token }))
    navigate('/app/content-management/manage-banners')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <Fragment>
      <Breadcrumb
        items={[{ label: `${t('common:text_initial')}` }, { label: 'Dashboard', path: '/app' }]}
      />
      <div css={styles.root}>
        <div css={styles.main}>
          <Traffic />
          {/*<ExpandYourPlan />*/}
        </div>
        {/*<div css={styles.right}>
          <Resource />
        </div>*/}
      </div>
    </Fragment>
  )
}

export default Dashboard
