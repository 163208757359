/** @jsxImportSource @emotion/react **/
import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../firebaseConfig";

import { LANG_ALL, styleContainer, validURL } from '../../../pages/utils';
import { Context } from '../../../context/FoundersContext'
import { BaseInput, FileInput, Text, TextareaInput } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from "../Header/index.styles";

const EnterpriseForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.get_founders.data)
  const news_data = id ? detail_data?.data.find((r: any) => r.id === id) : null
  const enterprises = useSelector((state: any) => state.get_enterprises.data)
  const [fileLogo, setFileLogo] = useState<any>();
  const [progressLogo, setProgressLogo] = useState<any>(null);
  const [image, setImage] = useState<string>()

  const [portofoliosOptions, setPortofoliosOptions] = useState<any>([])

  type portofoliosExtract = {
    id: string,
    title: string,
    lang: string,
  }

  useEffect(() => {
    const portofoliosConstructed = enterprises.data?.map(({ title, lang, id }: portofoliosExtract) =>
      ({ label: `${title} - ${lang}`, value: id })
    )

    setPortofoliosOptions(portofoliosConstructed)
    if (!!id) {
      handleData(news_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])


  // const handleChangeFile = (event: any) => {
  //   setFile(event)
  // }

  useEffect(() => {
    if (fileLogo) {
      handleUploadLogo()
    }
    // eslint-disable-next-line
  }, [fileLogo])

  const handleUploadLogo = () => {
    if (!fileLogo) {
      alert("Debe seleccionar una imagen");
    }
    // @ts-ignore: Object is possibly 'null'.
    const storageRef = ref(storage, `/files/${fileLogo!.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileLogo!);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressLogo(percent)
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          //handleChange({ image: url })
          setImage(url)
          setFileLogo(undefined);
        });
      }
    );
  };

  useEffect(() => {
    if (image) {
      handleChange({ image: image })
    }
    // eslint-disable-next-line
  }, [image])

  const langInit = news_data?.lang ? { value: news_data.lang, label: news_data.lang } : { value: '', label: '' }
  const portofolioInit = { value: '', label: '' }
  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isNameValid, setNameValid] = useState<boolean>(false)
  const [isLinkedinValid, setLinkedinValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.full_name.length > 2)
  }, [formData.full_name])

  useEffect(() => {
    setNameValid(formData.job_position.length > 0)
  }, [formData.job_position])

  useEffect(() => {
    const temp = validURL(formData.linkedin)
    setLinkedinValid(temp)
  }, [formData.linkedin])

  const handleLang = (e: any) => {
    handleChange({ lang: e.value })
  }

  return (
    <ContentSection
      style={styleContainer}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} Fundador</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Nombres'
        value={formData.full_name}
        onChange={(value) => handleChange({ full_name: value })}
        placeholder='Nombre completo'
        maxLength={500}
        error={!isTitleValid && formData.full_name.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.full_name.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <BaseInput
        label='Posición'
        value={formData.job_position}
        onChange={(value) => handleChange({ job_position: value })}
        placeholder='Posición'
        maxLength={500}
        error={!isNameValid && formData.job_position.length > 0}
        success={isNameValid}
        hint={!isNameValid && formData.job_position.length > 0 ? 'Digite mas de una letra' : ''}
      />
      <TextareaInput
        label='Descripción'
        value={formData.comment ? formData.comment : ''}
        onChange={(value) => handleChange({ comment: value })}
        placeholder='Escribe la descripciòn larga'
        maxLength={500}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label="Imagen( formato:.PNG/.JPG tamaño: 630x630px )"
          value={fileLogo}
          onChange={(event) => setFileLogo(event)}
          accept="image/*"
        />
        <Text textType='h4' weight='black'>{progressLogo === 100 && 'Imagen subida'}</Text>
      </div>


      <BaseInput
        label='Linkedin'
        value={formData.linkedin}
        onChange={(value) => handleChange({ linkedin: value })}
        placeholder='URL de linkedin'
        maxLength={1500}
        error={!isLinkedinValid && formData.linkedin.length > 0}
        success={isLinkedinValid}
        hint={!isLinkedinValid && formData.linkedin.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />

      <Text textType="label">
        EMPRESA:
        <Select css={styles.input}
          options={portofoliosOptions}
          defaultValue={portofolioInit}
          onChange={(e: any) => handleChange({ enterprise: e.value })}
        />
      </Text>
      <Text textType="label">
        IDIOMA :{' '}
        <Select css={styles.input}
          options={LANG_ALL}
          defaultValue={langInit}
          onChange={(e) => handleLang(e)}
        />
      </Text>
      {/* <SwitchInput
        checked={formData.isActive}
        onCheckedChange={(value) => handleChange({ isActive: value })}
        label={formData.isActive ? 'Activo' : 'Inactivo'}
      /> */}

    </ContentSection>
  )
}

export default EnterpriseForm
