/** @jsxImportSource @emotion/react */
import React from 'react'
import { HowToApplyForm, HowToApplyPreview } from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/HowToApplyContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, howToApplyManagement } = breadcrumbItems

const OurWorkCreate: React.FC = () => {
  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, howToApplyManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <HowToApplyForm />
        </div>
        <div css={styles.right}>
          <HowToApplyPreview />
        </div>
      </div>
    </Provider>
  )
}

export default OurWorkCreate;
