/** @jsxImportSource @emotion/react */
import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import { CreateSide, ManageTable } from '../../../components/templates'
import * as styles from '../index.styles'
import { columns } from './mockData'
import { useLocation } from "react-router-dom";
import { Breadcrumb } from '../../../components/atoms'
import { breadcrumbItems } from '../../utils'

const { index, contentManagement, portofolioManagement } = breadcrumbItems

const ManagePortofolio: React.FC = () => {
  const navigate = useNavigate()
  let location = useLocation();
  const data = useSelector((state: any) => state.get_portofolio.data)

  useEffect(() => {
    // dispatch(banners({ token }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.pathname === '/app/content-management/manage-potofolio') {
      setTimeout(() => {
        // dispatch(banners({ token }));
      }, 1500)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Fragment>
      <Breadcrumb items={[index, contentManagement, portofolioManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <ManageTable
            title="Administrar - Portafolio"
            subtitle="Portafolio - publicados"
            columns={columns}
            data={data ? data?.data : []}
            route={'/app/content-management/create-portofolio/'}
          />
        </div>
        <div css={styles.right}>
          <CreateSide
            title="Crear nuevo - Portafolio"
            message=""
            textButton="Crear nuevo"
            onCreate={() => navigate('/app/content-management/create-portofolio')}
          />
        </div>
      </div>
    </Fragment>
  )
}

export default ManagePortofolio;
