/** @jsxImportSource @emotion/react */
import React, { useContext, useState } from 'react'
import { css, useTheme } from '@emotion/react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ThemeUI } from '../../../theme'
import { Button, Card, Text } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from './index.styles'
import { Context } from "../../../context/FoundersContext";
//import { get_enterprises } from '../../../redux/actions/enterprises/get_enterprises';
import Modal from '../Modal/index';
import { delete_founders } from '../../../redux/actions/founders/delete_founders';
import { post_founders } from '../../../redux/actions/founders/post_founders';
import { put_founders } from '../../../redux/actions/founders/put_founders';

const EnterprisePreview: React.FC = () => {
  const dispatch = useDispatch()
  const theme = useTheme() as ThemeUI
  const navigate = useNavigate();
  const { formData } = useContext(Context)
  const token = useSelector((state: any) => state.login.access)
  let { id } = useParams();
  const [showModal, setShowModal] = useState<boolean>(false)
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false)


  const handleChange = (token: any, formData: any) => {
    if (id) {
      console.log(id)
      dispatch(put_founders({ id, token, data: formData, navigate }))
    } else {
      delete formData.id
      dispatch(post_founders({ token, data: formData, navigate }))
    }
  }

  const handleDelete = () => {
    dispatch(delete_founders({ id, token, data: formData, navigate }))
    //dispatch(get_banners({ lang: null, token }))
  }


  const successAction = () => {
    handleChange(token, formData)
    //dispatch(get_banners({ lang: null, token }))
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const closeModalDelete = () => {
    setShowModalDelete(false)
  }


  return (
    <ContentSection
      style={styles.root}
      header={
        <Text textType='p' weight='bold'>Vista Previa</Text>
      }
    >
      <div css={(theme: any) => styles.preview(theme)}>
        <Card style={css({ overflow: 'hidden' })}>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.full_name}
          </Text>
          {
            formData.image &&
            (<img
              src={formData.image}
              alt='placeholder'
              css={styles.previewImage}
            />)
          }

          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.job_position}
          </Text>
          <Text textType='sm' style={styles.previewTitle(theme)}>
            {formData.linkedin}
          </Text>
        </Card>
      </div>
      <Button onClick={() => {
        setShowModal(true)
      }} disabled={!formData.full_name || !formData.lang || !formData.enterprise || !formData.job_position || !formData.linkedin}>
        {!id ? 'Publicar' : 'Guardar'} Fundador
      </Button>

      {
        id ?
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de modificar este fundador?'}
              text={' '}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal} />
          ) :
          (
            <Modal
              show={showModal}
              title={'¿Esta seguro de publicar este fundador?'}
              text={''}
              onClickSuccess={successAction}
              onClickClose={closeModal}
              btnSuccess={'Si'}
              btnWarning={"No"}
              onClickWarning={closeModal}
            />
          )
      }



      {id && (
        <>
          <Button onClick={() => {
            setShowModalDelete(true)
          }}>
            Eliminar fundador
          </Button>
          <Modal
            show={showModalDelete}
            title={'¿Esta seguro de eliminar este fundador?'}
            text={' '}
            onClickSuccess={handleDelete}
            onClickClose={closeModalDelete}
            btnSuccess={'Si'}
            btnWarning={"No"}
            onClickWarning={closeModalDelete}
          />
        </>
      )}
    </ContentSection>
  )
}


export default EnterprisePreview;
