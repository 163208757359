import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  isActive: boolean
  lang: string
  title: string
  description: string
  href: string
  image: string
  button: string
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  isActive: true,
  lang: '',
  title: '',
  description: '',
  href: '',
  image: '',
  button: '',
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        isActive: data.isActive || false,
        lang: data.lang || initialData.lang,
        title: data.title || initialData.title,
        description: data.description || initialData.description,
        href: data.href || initialData.href,
        image: data.image || initialData.image,
        button: data.button || initialData.button,
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
