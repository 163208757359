
import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_INFO_TALENT_START, POST_INFO_TALENT_ERROR, POST_INFO_TALENT_START, POST_INFO_TALENT_SUCCESS } from '../../types';

import apiCall from '../../../api';

export function* create({ payload }) {
  try {
    const url = '/info-talent/create';
    const method = 'POST';
    const data = payload.data;
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, data, headers);
    console.log(results)
    yield put({ type: POST_INFO_TALENT_SUCCESS, results: results.data });
  } catch (error) {
    console.log(error)
    yield put({ type: POST_INFO_TALENT_ERROR, error });
  } finally {
    yield put({ type: GET_INFO_TALENT_START, payload: {lang: null, token: payload.token} });
    payload.navigate(-1);
  }
}

export default function* post_info_talent() {
  yield takeLatest(POST_INFO_TALENT_START, create);
}