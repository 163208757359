/** @jsxImportSource @emotion/react **/
import React, { FC, useContext, useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import storage from "../../../firebaseConfig";

import { LANG_ALL, styleContainer, validURL } from '../../../pages/utils';
import { Context } from '../../../context/EnterprisesContext'
import { BaseInput, FileInput, Text, TextareaInput } from '../../atoms'
import { ContentSection } from '../../organisms'
import * as styles from "../Header/index.styles";

const EnterpriseForm: FC = () => {
  const { formData, handleChangeFormData: handleChange, handleUpdateFormData: handleData } = useContext(Context)
  let { id } = useParams();
  const detail_data = useSelector((state: any) => state.get_enterprises.data)
  const news_data = id ? detail_data?.data.find((r: any) => r.id === id) : null
  const portofolios = useSelector((state: any) => state.get_portofolio.data)
  const [fileLogo, setFileLogo] = useState<any>();
  const [fileImageCard, setFileImageCard] = useState<any>();
  const [fileBanner, setFileBanner] = useState<any>();
  const [progressLogo, setProgressLogo] = useState<any>(null);
  const [progressImageCard, setProgressImageCard] = useState<any>(null);
  const [progressBanner, setProgressBanner] = useState<any>(null);
  const [imageLogo, setImageLogo] = useState<string>()
  const [imageCard, setImageCard] = useState<string>()
  const [imageBanner, setImageBanner] = useState<string>()

  const [portofoliosOptions, setPortofoliosOptions] = useState<any>([])

  type portofoliosExtract = {
    id: string,
    title: string,
    lang: string,
  }

  useEffect(() => {
    const portofoliosConstructed = portofolios.data?.map(({ title, lang, id }: portofoliosExtract) =>
      ({ label: `${title} - ${lang}`, value: id })
    )

    setPortofoliosOptions(portofoliosConstructed)
    if (!!id) {
      handleData(news_data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, news_data])


  // const handleChangeFile = (event: any) => {
  //   setFile(event)
  // }

  useEffect(() => {
    if (fileLogo) {
      handleUploadLogo()
    }
    if (fileImageCard) {
      handleUploadImageCard()
    }
    if (fileBanner) {
      handleUploadBanner()
    }
    // eslint-disable-next-line
  }, [fileLogo, fileImageCard, fileBanner])

  const handleUploadLogo = () => {
    if (!fileLogo) {
      alert("Debe seleccionar una imagen");
    }
    // @ts-ignore: Object is possibly 'null'.
    const storageRef = ref(storage, `/files/${fileLogo!.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileLogo!);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressLogo(percent)
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          //handleChange({ logo: url })
          setImageLogo(url)
          setFileLogo(undefined);
        });
      }
    );
  };

  useEffect(() => {
    if (imageLogo) {
      handleChange({ logo: imageLogo })
    }
    // eslint-disable-next-line
  }, [imageLogo])

  const handleUploadImageCard = () => {
    if (!fileImageCard) {
      alert("Debe seleccionar una imagen");
    }
    // @ts-ignore: Object is possibly 'null'.
    const storageRef = ref(storage, `/files/${fileImageCard!.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileImageCard!);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressImageCard(percent)
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          //handleChange({ image_card: url })
          setImageCard(url)
          setFileImageCard(undefined);
        });
      }
    );
  };

  useEffect(() => {
    if (imageCard) {
      handleChange({ image_card: imageCard })
    }
    // eslint-disable-next-line
  }, [imageCard])

  const handleUploadBanner = () => {
    if (!fileBanner) {
      alert("Debe seleccionar una imagen");
    }
    // @ts-ignore: Object is possibly 'null'.
    const storageRef = ref(storage, `/files/${fileBanner!.name}`);
    const uploadTask = uploadBytesResumable(storageRef, fileBanner!);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressBanner(percent)
      },
      (err) => console.log(err),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          //handleChange({ banner: url })
          setImageBanner(url)
          setFileBanner(undefined);
        });
      }
    );
  };


  useEffect(() => {
    if (imageBanner) {
      handleChange({ banner: imageBanner })
    }
    // eslint-disable-next-line
  }, [imageBanner])


  const langInit = news_data?.lang ? { value: news_data.lang, label: news_data.lang } : { value: '', label: '' }
  const portofolioInit = formData?.portofolio ? { value: formData.portofolio, label: formData.portofolio } : { value: '', label: '' }
  const [isTitleValid, setTitleValid] = useState<boolean>(false)
  const [isNameValid, setNameValid] = useState<boolean>(false)
  const [isShortDescriptionValid, setShortDescriptionValid] = useState<boolean>(false)
  const [isLargeDescriptionValid, setLargeDescriptionValid] = useState<boolean>(false)
  const [isFacebookValid, setFacebookValid] = useState<boolean>(false)
  const [isLinkedinValid, setLinkedinValid] = useState<boolean>(false)
  const [isInstagramValid, setInstagramValid] = useState<boolean>(false)
  const [isUrlValid, setUrlValid] = useState<boolean>(false)
  const [isHrefValid, setHrefValid] = useState<boolean>(false)

  useEffect(() => {
    setTitleValid(formData.title.length > 2)
  }, [formData.title])

  useEffect(() => {
    setNameValid(formData.name.length > 0)
  }, [formData.name])

  useEffect(() => {
    setShortDescriptionValid(formData.short_description.length > 0)
  }, [formData.short_description])

  useEffect(() => {
    setLargeDescriptionValid(formData.large_description.length > 0)
  }, [formData.large_description])

  useEffect(() => {
    const temp = validURL(formData.linkedin)
    setLinkedinValid(temp)
  }, [formData.linkedin])

  useEffect(() => {
    const temp = validURL(formData.facebook)
    setFacebookValid(temp)
  }, [formData.facebook])

  useEffect(() => {
    const temp = validURL(formData.instragram)
    setInstagramValid(temp)
    console.log(formData.instragram)
  }, [formData.instragram])

  useEffect(() => {
    const temp = validURL(formData.url)
    setUrlValid(temp)
  }, [formData.url])

  useEffect(() => {
    setHrefValid(formData.href.length > 0)
  }, [formData.href])

  const handleLang = (e: any) => {
    handleChange({ lang: e.value })
  }

  return (
    <ContentSection
      style={styleContainer}
      header={
        <React.Fragment>
          <Text textType='h4' weight='black'>{id ? 'Editar' : 'Nuevo'} Empresas</Text>
        </React.Fragment>
      }
    >
      <BaseInput
        label='Título'
        value={formData.title}
        onChange={(value) => handleChange({ title: value })}
        placeholder='Escribe el titulo de tu entrada'
        maxLength={500}
        error={!isTitleValid && formData.title.length > 0}
        success={isTitleValid}
        hint={!isTitleValid && formData.title.length > 0 ? 'Digite mas de dos letras' : ''}
      />
      <BaseInput
        label='Nombre'
        value={formData.name}
        onChange={(value) => handleChange({ name: value })}
        placeholder='Escribe un nombre'
        maxLength={500}
        error={!isNameValid && formData.name.length > 0}
        success={isNameValid}
        hint={!isNameValid && formData.name.length > 0 ? 'Digite mas de una letra' : ''}
      />
      <BaseInput
        label='Descripciòn corta'
        value={formData.short_description}
        onChange={(value) => handleChange({ short_description: value })}
        placeholder='Escribe una descripciòn corta'
        maxLength={500}
        error={!isShortDescriptionValid && formData.short_description.length > 0}
        success={isShortDescriptionValid}
        hint={!isShortDescriptionValid && formData.short_description.length > 0 ? 'Digite mas de una letra' : ''}
      />

      <TextareaInput
        label='Descripciòn larga'
        value={formData.large_description}
        onChange={(value) => handleChange({ large_description: value })}
        placeholder='Escribe la descripciòn larga'
        maxLength={1500}
        error={!isLargeDescriptionValid && formData.large_description.length > 0}
        success={isLargeDescriptionValid}
        hint={!isLargeDescriptionValid && formData.large_description.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />
      <div style={{ width: 360 }}>
        <FileInput
          label="Logo / Imagen( formato:.PNG/.JPG tamaño: 50x50px )"
          value={fileLogo}
          onChange={(event) => setFileLogo(event)}
          accept="image/*"
        />
        <Text textType='h4' weight='black'>{progressLogo === 100 && 'Imagen subida'}</Text>
      </div>

      <div style={{ width: 360 }}>
        <FileInput
          label="Imagen de tarjeta / Imagen( formato:.PNG/.JPG tamaño: 635x630px )"
          value={fileImageCard}
          onChange={(event) => setFileImageCard(event)}
          accept="image/*"
        />
        <Text textType='h4' weight='black'>{progressImageCard === 100 && 'Imagen subida'}</Text>
      </div>

      <div style={{ width: 360 }}>
        <FileInput
          label="Banner/Imagen( formato:.PNG/.JPG tamaño: 1300x630px )"
          value={fileBanner}
          onChange={(event) => setFileBanner(event)}
          accept="image/*"
        />
        <Text textType='h4' weight='black'>{progressBanner === 100 && 'Imagen subida'}</Text>
      </div>


      <BaseInput
        label='Linkedin'
        value={formData.linkedin}
        onChange={(value) => handleChange({ linkedin: value })}
        placeholder='URL de linkedin'
        maxLength={1500}
        error={!isLinkedinValid && formData.linkedin.length > 0}
        success={isLinkedinValid}
        hint={!isLinkedinValid && formData.linkedin.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />
      <BaseInput
        label='Facebook'
        value={formData.facebook}
        onChange={(value) => handleChange({ facebook: value })}
        placeholder='URL de facebook'
        maxLength={1500}
        error={!isFacebookValid && formData.facebook.length > 0}
        success={isFacebookValid}
        hint={!isFacebookValid && formData.facebook.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />
      <BaseInput
        label='Instagram'
        value={formData.instragram}
        onChange={(value) => handleChange({ instragram: value })}
        placeholder='URL de instagram'
        maxLength={1500}
        error={!isInstagramValid && formData.instragram.length > 0}
        success={isInstagramValid}
        hint={!isInstagramValid && formData.instragram.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />
      <BaseInput
        label='URL'
        value={formData.url}
        onChange={(value) => handleChange({ url: value })}
        placeholder='URL'
        maxLength={1500}
        error={!isUrlValid && formData.url.length > 0}
        success={isUrlValid}
        hint={!isUrlValid && formData.url.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />
      <BaseInput
        label='href'
        value={formData.href}
        onChange={(value) => handleChange({ href: value })}
        placeholder='href'
        maxLength={1500}
        error={!isHrefValid && formData.href.length > 0}
        success={isHrefValid}
        hint={!isHrefValid && formData.href.length > 0 ? 'Introduce una url Ej.:https://via.placeholder.com/600x200' : ''}
      />

      <Text textType="label">
        PORTAFOLIOS:
        <Select css={styles.input}
          options={portofoliosOptions}
          defaultValue={portofolioInit}
          onChange={(e: any) => handleChange({ portofolio: e.value })}
        />
      </Text>
      <Text textType="label">
        IDIOMA :{' '}
        <Select css={styles.input}
          options={LANG_ALL}
          defaultValue={langInit}
          onChange={(e) => handleLang(e)}
        />
      </Text>
      {/* <SwitchInput
        checked={formData.isActive}
        onCheckedChange={(value) => handleChange({ isActive: value })}
        label={formData.isActive ? 'Activo' : 'Inactivo'}
      /> */}

    </ContentSection>
  )
}

export default EnterpriseForm
