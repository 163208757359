export const options_i18 = [
  {
    value: 'es',
    label: 'ES',
  },
  {
    value: 'en',
    label: 'EN',
  }
]
