import { DELETE_INFO_TALENT_ERROR, DELETE_INFO_TALENT_START, DELETE_INFO_TALENT_SUCCESS } from '../../types';

export default function delete_info_talent(state, action) {
  switch (action.type) {
    case DELETE_INFO_TALENT_START:
      return { ...state };
    case DELETE_INFO_TALENT_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case DELETE_INFO_TALENT_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}