import { GET_OUR_WORK_ERROR, GET_OUR_WORK_START, GET_OUR_WORK_SUCCESS } from '../../types';

export default function get_our_work(state, action) {
  switch (action.type) {
    case GET_OUR_WORK_START:
      return { ...state };
    case GET_OUR_WORK_SUCCESS:
      return {
        ...state,
        data: action.results,
        error: null,
      };
    case GET_OUR_WORK_ERROR:
      return {
        ...state,
        data: null,
        error: action.error
      };
    default:
      return { ...state };
  }
}
