/** @jsxImportSource @emotion/react */
import React from 'react'
import { FoundersForm, FoundersPreview } from '../../../components/templates'
import * as styles from '../index.styles'
import Provider from '../../../context/FoundersContext'
import { breadcrumbItems } from '../../utils'
import { Breadcrumb } from '../../../components/atoms'

const { index, contentManagement, foundersManagement } = breadcrumbItems

const EnterprisesCreate: React.FC = () => {
  return (
    <Provider>
      <Breadcrumb items={[index, contentManagement, foundersManagement]} />
      <div css={styles.root}>
        <div css={styles.main}>
          <FoundersForm />
        </div>
        <div css={styles.right}>
          <FoundersPreview />
        </div>
      </div>
    </Provider>
  )
}

export default EnterprisesCreate;
