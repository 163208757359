/** @jsxImportSource @emotion/react */
import React from 'react'
import { Text } from '../../atoms'
import { Notifications } from '../../organisms'
import * as styles from './index.styles'
import {useTranslation} from "react-i18next";

const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div css={styles.root}>
      <Text textType='h3' weight='black'>{t('common:text_title')}</Text>
      <Notifications />
    </div>
  )
}

export default Header
