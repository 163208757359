import { takeLatest, call, put } from 'redux-saga/effects';
import { GET_OUR_WORK_START, POST_OUR_WORK_ERROR, POST_OUR_WORK_START, POST_OUR_WORK_SUCCESS } from '../../types';

import apiCall from '../../../api';

export function* create({ payload }) {
  try {
    const url = '/our-work/create';
    const method = 'POST';
    const data = payload.data;
    const headers = {Authorization: `Bearer ${payload.token}`};
    const results = yield call(apiCall, url, method, data, headers);
    console.log(results)
    // yield put({ type: POST_OUR_WORK_SUCCESS, results: results.data });
    yield put({type: POST_OUR_WORK_SUCCESS, results: results.data});
  } catch (error) {
    console.log(error)
    yield put({type: POST_OUR_WORK_ERROR, error});
  } finally {
    yield put({ type: GET_OUR_WORK_START, payload: {lang: null, token: payload.token} });
    payload.navigate(-1);
  }
}

export default function* post_our_work() {
  yield takeLatest(POST_OUR_WORK_START, create);
}