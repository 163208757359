/** @jsxImportSource @emotion/react */
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Text } from '../../atoms'
import { NavSectionProps } from './types'
import * as styles from './index.styles'

const NavSection: React.FC<NavSectionProps> = ({ routeSection: { title, children: routes } }) => {
  const { pathname } = useLocation()
  //console.log(pathname)
  return (
    <div css={styles.root}>
      {title && (
        <div css={(theme: any) => styles.title({ theme })}>
          <Text textType='labelSm'>{title}</Text>
        </div>
      )}
      <ul css={styles.list}>
        {
          routes.map(({ path = '', text, children, icon: Icon }) => (
            (!children) ? (
              <li
                key={`navbarSection-${text}`}
                css={(theme: any) => styles.item({ theme, isActive: pathname === path })}
              >
                <Link to={path}>
                  <Icon />
                  <Text textType='sm'>{text}</Text>
                </Link>
              </li>
            ) : (
              <li key={`navbarSection-${text}`}>
                <details>
                  <summary css={(theme: any) => styles.linkStyle({ theme, isActive: pathname === path })}>
                    <Icon />
                    <Text textType='sm'>{text}</Text>
                  </summary>
                  <ul css={styles.subList}>
                    {
                      children.map(({ path: childPath, text: childText }) => (
                        <li
                          key={`navbarItem-${childText}`}
                          css={(theme: any) => styles.subItem({ theme, isActive: pathname === childPath })}
                        >
                          <Text textType='sm'>
                            <Link to={childPath}>&bull; &nbsp; {childText}</Link>
                          </Text>
                        </li>
                      ))
                    }
                  </ul>
                </details>
              </li>
            )
          ))
        }
      </ul>
    </div>
  )
}

export default NavSection
