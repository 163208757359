import { Story, Meta } from '@storybook/react'
import { default as Component } from './index'
import { ButtonProps } from './types'

const metaData: Meta = {
  title: 'atoms/Button',
  component: Component,
  argTypes: {
    onClick: { action: 'clicked' },
  }
}

const Template: Story<ButtonProps> = (props) => (<Component{...props} />)

export const Button = Template.bind({})
Button.args = {
  children: 'Button',
};

export default metaData
