import { createContext, FC, useState } from 'react'

export type Data = {
  id?: string
  isActive: boolean
  lang: string
  name: string
  image: string
  job_position: string
  linkedin: string
  staff: string
}
export interface DataContext {
  formData: Data,
  handleChangeFormData: (data: Partial<Data>) => void,
  handleUpdateFormData: (data: Partial<Data>) => void,
}

const initialData: Data = {
  id: '',
  isActive: true,
  lang: '',
  name: '',
  image: '',
  job_position: '',
  linkedin: '',
  staff: '',
}

export const Context = createContext<DataContext>({
  formData: initialData,
  handleChangeFormData: (data: Partial<Data>) => { },
  handleUpdateFormData: (data: Partial<Data>) => { },
})

const NewFormDataProvider: FC = (props) => {
  const [formData, setFormData] = useState<Data>(initialData)

  const handleChangeFormData = (data: Partial<Data>) => {
    setFormData({ ...formData, ...data })
  }

  const handleUpdateFormData = (data: Partial<Data>) => {
    if (data && data?.id) {
      setFormData({
        ...formData,
        id: data.id,
        isActive: data.isActive || false,
        lang: data.lang || initialData.lang,
        name: data.name || initialData.name,
        image: data.image || initialData.image,
        job_position: data.job_position || initialData.job_position,
        linkedin: data.linkedin || initialData.linkedin,
        staff: data.staff || initialData.staff,
      })
    }
  }
  return (
    <Context.Provider value={{ formData, handleChangeFormData, handleUpdateFormData }}>
      {props.children}
    </Context.Provider>
  )
}

export default NewFormDataProvider
