export const columns = [
  {
    Header: 'Titulo',
    accessor: 'title',
  },
  {
    Header: 'Idioma',
    accessor: 'lang',
  },
  {
    Header: 'Estado',
    accessor: 'isActive',
  },
  {
    Header: 'Acciones',
    accessor: 'actions',
  },
]

export const data = [
  {
    title: 'Titulo 1',
    content: 'Contenido 1',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
  {
    title: 'Titulo 2',
    content: 'Contenido 2',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
  {
    title: 'Titulo 3',
    content: 'Contenido 3',
    image: 'https://via.placeholder.com/150',
    link: 'https://via.placeholder.com/150',
  },
]
